import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppwriteAvatarsService } from '@appwrite/angular';
import { User } from '@appwrite/common';
import { CategoryDto } from '@mm-mono/data/dto';
import { AuthService } from '@appwrite/angular';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { firstValueFrom, Subscription } from 'rxjs';
import { LogoComponent } from '../logo/logo.component';
import { GreenBarComponent } from './green-bar.component';
import { OrangeBarComponent } from './orange-bar.component';

@Component({
  selector: 'header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrls: [],
  imports: [OverlayPanelModule, OrangeBarComponent, GreenBarComponent, LogoComponent, RouterModule, CommonModule],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public items: CategoryDto[] = [];
  public user: User;

  public isCollapsed = true;
  public currentWindowWidth: number;
  private subscription: Subscription = new Subscription();

  constructor(public avatarService: AppwriteAvatarsService, public authService: AuthService) {}

  ngOnInit() {
    this.currentWindowWidth = window.innerWidth;
    this.subscription.add(this.authService.$userData.subscribe(({ account }) => (this.user = account)));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public toggle() {
    this.isCollapsed = !this.isCollapsed;
  }

  @HostListener('window:resize')
  onResize() {
    this.currentWindowWidth = window.innerWidth;
  }

  logout() {
    firstValueFrom(this.authService.logout()).then();
  }
}
