import { AppWriteBaseModel } from '@mm-mono/data/appwrite';

export type EmployeesDto = EmployeeDto[];

export interface EmployeeDto {}

export class EmployeeDto extends AppWriteBaseModel {
  firstName: string = null;
  lastName: string = null;
  bio: string = null;
  title: string = null;
  department: string = null;
  image: string[] = null;
  active?: boolean = null;

  constructor(data?: Partial<EmployeeDto>) {
    super();
    Object.keys(this).forEach((k) => (this[k] = data?.[k] ?? this[k]));
  }
}
