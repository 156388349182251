export enum AwFunctions {
  createUserCarts = '60c7510830140',
  defaultPermissions = '611510b26e8f9',
  linkContactCode = '6298e5f7c5adaaeb06bd',
  msSql = '6241fdc9876b5e26dc74',
  newTest = '623a2523c0e7e40b16e5',
  removeOldListings = '612d2ceb66688',
  removeUserCarts = '612e284288537',
  sapOrders = '635b0812b0ee8e2beb16',
  sapServiceLayer = '6572254482f18471d012',
  sendMail = '60f03debc3998',
  webFormSubmissions = '60f02de96753b',
  adminData = '60f02de96753b',
}
