import { Route, Routes } from '@angular/router';
import { AuthForgotComponent } from './auth-forgot.component';
import { AuthLoginComponent } from './auth-login.component';
import { AuthRegisterComponent } from './auth-register.component';
import { AuthResetComponent } from './auth-reset.component';
import { AuthVerifyComponent } from './auth-verify.component';

export interface AuthLoginStateParams {
  code: number;
  message: string;
  title: string;
  from: any;
  to: any;
}

export const loginRoute: Route = {
  path: 'login',
  component: AuthLoginComponent,
};
export const forgotRoute: Route = {
  path: 'forgot',
  component: AuthForgotComponent,
};
export const registerRoute: Route = {
  path: 'register',
  component: AuthRegisterComponent,
};
export const resetRoute: Route = {
  path: 'reset',
  component: AuthResetComponent,
};
export const verifyRoute: Route = {
  path: 'verify',
  component: AuthVerifyComponent,
};

export const AUTH_ROUTES: Routes = [loginRoute, forgotRoute, resetRoute, verifyRoute];
