import { Inject, Injectable } from '@angular/core';
import { DEFAULT_ERRORS } from '../default-errors';
import { ErrorMessage } from '../Models/error-message';
import { CUSTOM_ERROR_MESSAGES } from '../Tokens/tokens';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  public errorMessages: ErrorMessage[];
  private defaultErrors = DEFAULT_ERRORS;

  constructor(@Inject(CUSTOM_ERROR_MESSAGES) public customErrorMessages: ErrorMessage[][]) {
    this.errorMessages = customErrorMessages.reduce((acc, cur) => acc.concat(cur), this.defaultErrors);
  }
}
