import { map, OperatorFunction } from 'rxjs';

type Flatten<T> = T extends any[] ? T[number] : T;
type Plural<T, R> = T extends Array<any> ? R[] : R;

export function rxJsonParse<T, R extends Omit<Flatten<T>, Properties> & { [P in Properties]: any }, Properties extends keyof Flatten<T>>(
  ...properties: Properties[]
): OperatorFunction<T, Plural<T, R>> {
  return map((e) => {
    const it = (obj) => {
      for (const prop of properties) {
        while (typeof obj?.[prop] === 'string') {
          obj[prop] = JSON.parse(obj[prop]);
        }
      }
      return obj;
    };

    return Array.isArray(e) ? e.map((v) => it(v)) : it(e);
  });
}
