import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormGroupDirective, NgControl } from '@angular/forms';
import { camelize } from '@mm-mono/utilities';
import { autocompleteTypes, inputTypes } from './types';

@Component({
  selector: 'input-label',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  providers: [],

  template: `
    <div class="mb-3">
      <label [for]="name" class="mb-1 block text-sm font-medium text-gray-700">{{ label }}</label>
      @if (type !== 'textarea') {
      <input
        pInputText
        [autocomplete]="autocomplete"
        [id]="name"
        [name]="name"
        [type]="type"
        (blur)="onTouch()"
        [readOnly]="_readonly"
        [autofocus]="_autofocus"
        [disabled]="_disabled"
        [formControlName]="formControlName"
        [min]="min"
        [max]="max"
        [minlength]="minLength"
        [maxlength]="maxLength"
      />
      } @else if (type === 'textarea') {
      <textarea
        pInputTextarea
        [style.height.px]="120"
        [id]="name"
        [name]="name"
        [readOnly]="_readonly"
        [autofocus]="_autofocus"
        [disabled]="_disabled"
        [formControlName]="formControlName"
        [minlength]="minLength"
        [maxlength]="maxLength"
        (blur)="onTouch()"
      ></textarea>
      }
      <bfv-messages></bfv-messages>
    </div>
  `,
})
export class InputLabelComponent implements OnInit, ControlValueAccessor {
  @Input() public name = '';
  @Input() public label = '';
  @Input() public type: inputTypes = 'text';
  @Input() public min = null;
  @Input() public max = null;
  @Input() public minLength = null;
  @Input() public maxLength = null;
  @Input() public formControlName;
  @Input() public autocomplete: autocompleteTypes;

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      // Note: we provide the value accessor through here, instead of
      // the `providers` to avoid running into a circular import.
      // And we use NOOP_VALUE_ACCESSOR so WrappedInput don't do anything with NgControl
      this.ngControl.valueAccessor = {
        writeValue(): void {},
        registerOnChange(): void {},
        registerOnTouched(): void {},
      };
    }
  }

  public _autofocus: boolean = null;

  @Input()
  public set autofocus(value) {
    this.setValue('autofocus', value);
  }

  public _readonly: boolean = null;

  @Input()
  public set readonly(value) {
    this.setValue('readonly', value);
  }

  public _disabled: boolean = null;

  @Input()
  public set disabled(value) {
    this.setValue('disabled', value);
  }

  onChange: any = () => {};

  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public ngOnInit(): void {
    this.name = this.name || camelize(this.label, true).replace(/[^a-z0-9]+/gi, '');
  }

  public setDisabledState(isDisabled: boolean): void {
    console.log(isDisabled);
  }

  public writeValue(obj: any): void {}

  private setValue(key, value) {
    console.log({ key, value });
    switch (value) {
      case '':
      case true:
      case key:
        this[`_` + key] = true;
        break;
      default:
        this[`_` + key] = null;
    }
  }
}
