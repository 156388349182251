import { CRD1 } from '@sap/common';

export interface BillingShippingFormDto {}

export class BillingShippingFormDto extends CRD1 {
  Address: string = '';
  Address2: string = '';
  Address3: string = '';
  AddressName: string = '';
  AdresType: 'B' | 'S';
  BillToCode: string = '';
  Block: string = '';
  Building: string = '';
  CardCode: string = '';
  City: string = '';
  Country: string = null;
  County: string = '';
  LineNum: number = 0;
  U_Phone: string = '';
  ShipToCode: string = '';
  State: string = null;
  Street: string = '';
  StreetNo: string = '';
  ZipCode: string = '';
}
