import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BuildDetailsService } from './build-details.service';
import { BuildDetails } from './ngx-app-version-checker.module';

@Injectable()
export class UpToDateBuildService {
  public buildIsUpToDateSubject = new BehaviorSubject<boolean>(true);
  httpClient: HttpClient;
  handler: HttpBackend;
  private buildNumberAtStartup: number;

  constructor(private http: HttpClient, handler: HttpBackend, private buildDetailsService: BuildDetailsService) {
    this.httpClient = http;
    this.handler = handler;
    this.buildNumberAtStartup = buildDetailsService.buildDetails.buildDate;
    this.pollForBuildNumber();
  }

  public get buildIsUpToDate(): Observable<boolean> {
    return this.buildIsUpToDateSubject;
  }

  private pollForBuildNumber() {
    const pollIntervalMinutes = 2;
    const pollInterval = pollIntervalMinutes * 60 * 1000;

    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
      }),
    };
    this.httpClient = new HttpClient(this.handler);

    timer(pollInterval, pollInterval).subscribe(() => {
      this.httpClient
        .get<BuildDetails>('assets/build-details.json', httpOptions)
        .pipe(catchError((err, caught) => EMPTY))
        .subscribe(async (response) => {
          const newBuildNumber = response.buildDate;
          if (this.buildNumberAtStartup !== newBuildNumber) {
            this.buildIsUpToDateSubject.next(false);
          }
        });
    });
  }
}
