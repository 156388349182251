import { Route, Routes } from '@angular/router';
import { ErrorsComponent } from './errors.components';

export interface ErrorRouteData {
  title: string;
  code: number;
  message: string;
  from: any;
  to: any;
}

export const errorAbstractRoute: Route = {
  path: '**',
  component: ErrorsComponent,

  data: {
    title: 'Oops!',
    code: 404,
    message: 'Page Not Found!',
    from: null,
    to: null,
  },
};
export const errorsRoutes: Route = {
  path: 'error',
  component: ErrorsComponent,
  data: {
    title: 'Oops!',
    code: 404,
    message: 'Page Not Found!',
    from: null,
    to: null,
  },
};

export const ERROR_ROUTES: Routes = [errorsRoutes];
