import { AppWriteBaseModel } from '@mm-mono/data/appwrite';
import { ShopProductDto } from './shop-product.dto';

// import { CartType } from '@mm-mono/modules/store-front/common';

export interface ShopCartDto extends AppWriteBaseModel {}

export class ShopCartDto extends AppWriteBaseModel {
  name: string = '';
  description: string = '';
  cartType: 'LIST' | 'TEMP' = 'TEMP';
  items: ShopProductDto[] = [];

  constructor(data?: ShopCartDto) {
    super();
    Object.keys(this).forEach((k) => (this[k] = data?.[k] ?? this[k]));
  }
}
