import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AppwriteException } from '@appwrite/angular';
import { NgxDialogsService } from '@mm-mono/ngx/dialogs';
import * as Sentry from '@sentry/angular-ivy';
import { MessageService } from 'primeng/api';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  private zone: NgZone;
  private router: Router;
  private messageService: MessageService;
  private dialogsService: NgxDialogsService;

  constructor(private injector: Injector) {
    this.zone = this.injector.get(NgZone);
  }

  handleError(error: any): void {
    const err: Error = error.rejection || error;

    this.router = this.injector.get(Router);
    this.messageService = this.injector.get(MessageService);
    this.dialogsService = this.injector.get(NgxDialogsService);

    console.error(err);

    if (err?.name === 'ChunkLoadError') {
      this.dialogsService
        .alert({
          header: 'Update Available',
          message: 'A new version of the website has become available. The website will be reloaded.',
        })
        .subscribe(() => window.location.reload());
      return;
    }

    if (err instanceof AppwriteException) {
      this.messageService.add({ severity: 'error', summary: err.message });
      return;
    }

    const eventId = Sentry.captureException(err);
    // Sentry.showReportDialog({ eventId });

    if (err instanceof HttpErrorResponse) {
      let message: string = '';
      let title: string = '';

      switch (err.status) {
        case 400:
          message = 'The server could not understand the request due to invalid syntax.';
          title = 'Bad Request';
          break;
        case 401:
          message = 'You are not authorized to access this content.';
          title = 'Unauthorized';
          break;
        case 403:
          message = 'You are not allowed to access this content.';
          title = 'Forbidden';
          break;
        case 404:
          message = 'The requested resource could not be found.';
          title = 'Not Found';
          break;
        case 500:
          message = 'The server has encountered an unexpected error.';
          title = 'Internal Server Error';
          break;
        case 501:
          message = 'You initiated a request that the server does not understand.';
          title = 'Not Implemented';
          break;
        case 502:
        case 503:
        case 504:
          message = 'The server is offline or too busy to handle your request.';
          title = 'Service Unavailable';
          break;
        case 0:
          return;
        default:
          message = 'An unknown error has occurred.';
          title = 'Unknown Error';
          break;
      }

      if ([404, 500, 501, 502, 503, 504].some((code) => code === err.status)) {
        this.zone.run(() => this.router.navigate(['/error'], { queryParams: { title, message, code: err.status || 0 }, skipLocationChange: true }));
      }
    }
    this.zone.run(() =>
      this.router.navigate(['/error'], { queryParams: { title: 'Oops!', message: 'An error occurred.', code: 0 }, skipLocationChange: true })
    );
  }
}
