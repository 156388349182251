export enum AwCollections {
  article = '60d5200022a76',
  category = '60cd46349bb14',
  dealer = '636bf79fd1f5c9155a90',
  dealerLocation = '636bf7b2d32081995e09',
  employee = '6213e7ec20dfa',
  file = '63924ba02181265589d3',
  form = '6527f0e7ce057da592bb',
  monitorLog = '60f985e5cde85',
  order = '62279ae25f9d7',
  product = '60cd3c5478fe9',
  siteTable = '619c02f75743d',
  usedProduct = '60be2b677c49b',
  userCart = '60c74dc1b2404',
  webform = '60c89fbf883e9',
}
