import { ModuleWithProviders, NgModule, Optional, Provider, SkipSelf } from '@angular/core';
import { GOOGLE_OPTIONS } from './google.options';
import { GoogleService } from './google.service';

declare global {
  interface Window {
    gtag?: (...args: any) => void;
    dataLayer?: any;
  }
}

export * from './google.options';
export * from './google.service';

export interface GoogleModuleOptions {
  headerMetaOptionsProvider?: Provider;
  config?: {
    GA_TRACKING_ID: string;
    AW_CONVERSION_ID: string;
  };
}

@NgModule()
export class GoogleModule {
  constructor(@Optional() @SkipSelf() parentModule: GoogleModule) {
    if (parentModule) {
      throw new Error("GoogleModule is already loaded. It should only be imported in your application's main module.");
    }
  }

  static forRoot(options: GoogleModuleOptions): ModuleWithProviders<GoogleModule> {
    return {
      ngModule: GoogleModule,
      providers: [
        options.headerMetaOptionsProvider || {
          provide: GOOGLE_OPTIONS,
          useValue: options.config,
        },
        GoogleService,
      ],
    };
  }
}
