export interface CRD1 {}

export class CRD1 {
  public Address: string;
  public CardCode: string;
  public Street: string;
  public Block: string;
  public ZipCode: string;
  public City: string;
  public County: string;
  public Country: string;
  public State: string;
  public UserSign: number;
  public LogInstanc: number;
  public ObjType: string;
  public LicTradNum: string;
  public LineNum: number;
  public TaxCode: string;
  public Building: string;
  public AdresType: 'B' | 'S' = 'S';
  public Address2: string;
  public Address3: string;
  public AddrType: string;
  public StreetNo: string;
  public AltCrdName: string;
  public AltTaxId: string;
  public TaxOffice: string;
  public GlblLocNum: string;
  public Ntnlty: string;
  public DIOTNat: string;
  public TaaSEnbl: string;
  public GSTRegnNo: string;
  public GSTType: number;
  public CreateDate: string;
  public CreateTS: number;
  public U_Mailings: string;
  public U_Phone: string;
  public U_Fax: string;
  public U_Email: string;
  public U_ZED_EXTADDID: string;
  public U_V33_EBBERT: string;
}
