import * as inflection from 'inflection';

export function pluralize(str: string, plural?: string) {
  return inflection.pluralize(str, plural);
}

export function singularize(str: string, singular?: string) {
  return inflection.singularize(str, singular);
}

export function camelize(str: string, lowFirstLetter?: boolean) {
  return inflection.camelize(prepareInflection(str), lowFirstLetter);
}

export function underscore(str: string, allUpperCase?: boolean) {
  return inflection.underscore(str, allUpperCase);
}

export function humanize(str: string, lowFirstLetter?: boolean) {
  return inflection.humanize(str, lowFirstLetter);
}

export function capitalize(str: string) {
  return inflection.capitalize(str);
}

export function dasherize(str: string) {
  return inflection.dasherize(str);
}

export function titleize(str: string) {
  return inflection.titleize(str);
}

export function demodulize(str: string) {
  return inflection.demodulize(str);
}

export function tableize(str: string) {
  return inflection.tableize(str);
}

export function classify(str: string) {
  return inflection.classify(str);
}

export function foreignKey(str: string, dropIdUbar?: boolean) {
  return inflection.foreignKey(str, dropIdUbar);
}

export function ordinalize(str: string) {
  return inflection.ordinalize(str);
}

export function prepareInflection(str: string) {
  return str.replace(/[^a-z0-9_]+/gi, '_').replace(/_+/gi, '_');
}
