import { Component, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { AppwriteDatabasesService, AppwriteStorageService } from '@appwrite/angular';
import { FileDto } from '@mm-mono/data/dto';

@Component({
  selector: 'appwrite-image',
  template: `
    <ng-container *ngIf="src">
      <img
        (click)="openImage()"
        [alt]="src?.alt || metadata?.description || ''"
        [class.pointer]="clickable"
        [class]="imgClasses"
        [src]="originalSrc"
        [srcset]="srcSet"
        [style.max-width]="'100%'"
        [style.object-fit]="objectFit"
        [style.width]="'100%'"
        [title]="src?.title || metadata?.title || ''"
      />
    </ng-container>
  `,
  styles: [
    `
      :host {
        overflow: hidden;
        display: block;
        max-width: 100%;
      }

      img {
        border-radius: inherit;
      }
    `,
  ],
})
export class AppwriteImageComponent implements OnChanges {
  public srcSet: string = '';
  public originalSrc: string;
  @Input() public clickable?: boolean = false;
  @Input() public skipMeta?: boolean = false;
  @Input() public src: any;
  @Input() public width?: number;
  @Input() public height?: number;
  @Input() public quality?: number = 75;
  @Input() public gravity?: 'center' | 'top-left' | 'top' | 'top-right' | 'left' | 'right' | 'bottom-left' | 'bottom' | 'bottom-right';
  @Input() public borderWidth?: number;
  @Input() public borderColor?: string;
  @Input() public borderRadius?: number;
  @Input() public opacity?: number;
  @Input() public rotation?: number;
  @Input() public background?: string;
  @Input() public output?: 'jpeg' | 'jpg' | 'png' | 'gif' | 'webp';
  @Input() public objectFit?: string = 'contain';

  @Input() public imgClasses: string = '';
  public metadata: FileDto;

  constructor(private appwriteStorage: AppwriteStorageService, private databasesService: AppwriteDatabasesService) {}

  public openImage() {
    const url = this.appwriteStorage.getFilePreview('default', this.src, undefined, undefined, undefined, 90).href;
    if (this.clickable) window.open(url, '_blank');
  }

  public ngOnChanges(changes: SimpleChanges) {
    const change: SimpleChange = changes['src'];
    if (!change.currentValue) {
      return;
    }
    const currentValue = change.currentValue.$id || change.currentValue;
    this.url(currentValue);

    if (this.skipMeta) return;
    // from(this.databasesService.getDocument<FileDto>(AwDatabases.default, AwCollections.file, currentValue))
    //   .pipe(catchError(() => of(null)))
    //   .subscribe((d) => (this.metadata = d));
  }

  public url(id: string) {
    this.originalSrc = this.appwriteStorage.getFilePreview(
      'default',
      id,
      this.width,
      this.height,
      this.gravity,
      this.quality,
      this.borderWidth,
      this.borderColor,
      this.borderRadius,
      this.opacity,
      this.rotation,
      this.background,
      this.output
    ).href;

    this.srcSet = [1, 2]
      .map((resolutionFactor) => {
        let width = this.width * resolutionFactor || undefined;
        let height = this.height * resolutionFactor || undefined;
        if (width > 4000) width = 4000;
        if (height > 4000) height = 4000;
        return this.appwriteStorage.getFilePreview('default', id, width, height, undefined, this.quality).href + ` ${resolutionFactor}x`;
      })
      .join(', ');
  }
}
