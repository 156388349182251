import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxHighlightModule } from '@mm-mono/ngx-highlight';
import { NgxBootstrapFormValidationModule } from '@mm-mono/ngx/bootstrap-form-validation';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { NgxTableActions } from './components/ngx-table-actions';
import { NgxTablePageSizeComponent } from './components/ngx-table-page-size.component';
import { NgxTablePaginationComponent } from './components/ngx-table-pagination.component';
import { NgxTableSearchComponent } from './components/ngx-table-search.component';
import { NgxTableComponent } from './components/ngx-table.component';
import { TdComponent } from './components/td.component';
import { ThComponent } from './components/th.component';
import { TableRxModel } from './directive/table-rx.model';
import { NgxTableService } from './ngx-table.service';

export * from './components/ngx-table.component';
export * from './components/td.component';
export * from './components/th.component';
export * from './directive/table-rx.model';
export * from './interface/ISearchResult';
export * from './ngx-table.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxBootstrapFormValidationModule,
    NgxHighlightModule,
    InputTextModule,
    DropdownModule,
    PaginatorModule,
  ],
  providers: [NgxTableService],
  declarations: [
    TdComponent,
    ThComponent,
    TableRxModel,
    NgxTableComponent,
    NgxTableSearchComponent,
    NgxTablePageSizeComponent,
    NgxTablePaginationComponent,
    NgxTableActions,
  ],
  exports: [TdComponent, ThComponent, NgxTableComponent, NgxTableSearchComponent, NgxTablePageSizeComponent, NgxTablePaginationComponent, NgxTableActions],
})
export class NgxTableModule {}
