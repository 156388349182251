import { Attribute, Component, ElementRef, Input, OnInit, Optional } from '@angular/core';
import { NgxTableService } from '../ngx-table.service';

@Component({
  selector: 'td[ngTd]',
  template: `
    <ng-container *ngIf="highlight && data">
      <ngx-highlight [result]="data" [term]="ngTableService?.searchTerm | async"></ngx-highlight>
    </ng-container>

    <ng-container *ngIf="!highlight && data">{{ data }}</ng-container>

    <ng-content *ngIf="!data"></ng-content>
  `,
  styles: [
    `
      :host.fit {
        width: 1px;
        white-space: nowrap;
      }
    `,
  ],
})
export class TdComponent implements OnInit {
  @Input() public highlight: boolean = false;
  @Input() public data: any;

  constructor(private elm: ElementRef<HTMLTableHeaderCellElement>, @Optional() @Attribute('fit') public fit: any, public ngTableService: NgxTableService<any>) {
    this.fit = this.fit !== null;
  }

  public ngOnInit() {
    if (this.fit) this.elm.nativeElement.setAttribute('width', '1%');
  }
}
