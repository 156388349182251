import { MimeType as MimeTypeList } from 'file-type';

export type MimeTypes = MimeTypeList | 'image/svg+xml' | 'application/octet-stream';

export enum MimeType {
  'application/dicom' = 'application/dicom',
  'application/eps' = 'application/eps',
  'application/epub+zip' = 'application/epub+zip',
  'application/gzip' = 'application/gzip',
  'application/mxf' = 'application/mxf',
  'application/octet-stream' = 'application/octet-stream',
  'application/ogg' = 'application/ogg',
  'application/pdf' = 'application/pdf',
  'application/postscript' = 'application/postscript',
  'application/rtf' = 'application/rtf',
  'application/vnd.ms-asf' = 'application/vnd.ms-asf',
  'application/vnd.ms-cab-compressed' = 'application/vnd.ms-cab-compressed',
  'application/vnd.ms-fontobject' = 'application/vnd.ms-fontobject',
  'application/vnd.oasis.opendocument.presentation' = 'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet' = 'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text' = 'application/vnd.oasis.opendocument.text',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation' = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.sketchup.skp' = 'application/vnd.sketchup.skp',
  'application/vnd.tcpdump.pcap' = 'application/vnd.tcpdump.pcap',
  'application/wasm' = 'application/wasm',
  'application/x-7z-compressed' = 'application/x-7z-compressed',
  'application/x-apache-arrow' = 'application/x-apache-arrow',
  'application/x-apple-diskimage' = 'application/x-apple-diskimage',
  'application/x-blender' = 'application/x-blender',
  'application/x-bzip2' = 'application/x-bzip2',
  'application/x-compress' = 'application/x-compress',
  'application/x-deb' = 'application/x-deb',
  'application/x-esri-shape' = 'application/x-esri-shape',
  'application/x-google-chrome-extension' = 'application/x-google-chrome-extension',
  'application/x-lzip' = 'application/x-lzip',
  'application/x-mie' = 'application/x-mie',
  'application/x-mobipocket-ebook' = 'application/x-mobipocket-ebook',
  'application/x-msdownload' = 'application/x-msdownload',
  'application/x-msi' = 'application/x-msi',
  'application/x-nintendo-nes-rom' = 'application/x-nintendo-nes-rom',
  'application/x-rar-compressed' = 'application/x-rar-compressed',
  'application/x-rpm' = 'application/x-rpm',
  'application/x-shockwave-flash' = 'application/x-shockwave-flash',
  'application/x-sqlite3' = 'application/x-sqlite3',
  'application/x-tar' = 'application/x-tar',
  'application/x-unix-archive' = 'application/x-unix-archive',
  'application/x-xpinstall' = 'application/x-xpinstall',
  'application/x-xz' = 'application/x-xz',
  'application/x.apple.alias' = 'application/x.apple.alias',
  'application/x.ms.shortcut' = 'application/x.ms.shortcut',
  'application/xml' = 'application/xml',
  'application/zip' = 'application/zip',
  'audio/aac' = 'audio/aac',
  'audio/aiff' = 'audio/aiff',
  'audio/amr' = 'audio/amr',
  'audio/ape' = 'audio/ape',
  'audio/midi' = 'audio/midi',
  'audio/mp4' = 'audio/mp4',
  'audio/mpeg' = 'audio/mpeg',
  'audio/ogg' = 'audio/ogg',
  'audio/opus' = 'audio/opus',
  'audio/qcelp' = 'audio/qcelp',
  'audio/vnd.dolby.dd-raw' = 'audio/vnd.dolby.dd-raw',
  'audio/vnd.wave' = 'audio/vnd.wave',
  'audio/wavpack' = 'audio/wavpack',
  'audio/x-dsf' = 'audio/x-dsf',
  'audio/x-flac' = 'audio/x-flac',
  'audio/x-it' = 'audio/x-it',
  'audio/x-m4a' = 'audio/x-m4a',
  'audio/x-ms-wma' = 'audio/x-ms-wma',
  'audio/x-musepack' = 'audio/x-musepack',
  'audio/x-s3m' = 'audio/x-s3m',
  'audio/x-voc' = 'audio/x-voc',
  'audio/x-xm' = 'audio/x-xm',
  'font/otf' = 'font/otf',
  'font/ttf' = 'font/ttf',
  'font/woff' = 'font/woff',
  'font/woff2' = 'font/woff2',
  'image/apng' = 'image/apng',
  'image/avif' = 'image/avif',
  'image/bmp' = 'image/bmp',
  'image/bpg' = 'image/bpg',
  'image/flif' = 'image/flif',
  'image/gif' = 'image/gif',
  'image/heic' = 'image/heic',
  'image/heic-sequence' = 'image/heic-sequence',
  'image/heif' = 'image/heif',
  'image/heif-sequence' = 'image/heif-sequence',
  'image/jp2' = 'image/jp2',
  'image/jpeg' = 'image/jpeg',
  'image/jpm' = 'image/jpm',
  'image/jpx' = 'image/jpx',
  'image/ktx' = 'image/ktx',
  'image/mj2' = 'image/mj2',
  'image/png' = 'image/png',
  'image/svg+xml' = 'image/svg+xml',
  'image/tiff' = 'image/tiff',
  'image/vnd.adobe.photoshop' = 'image/vnd.adobe.photoshop',
  'image/vnd.ms-photo' = 'image/vnd.ms-photo',
  'image/webp' = 'image/webp',
  'image/x-adobe-dng' = 'image/x-adobe-dng',
  'image/x-canon-cr2' = 'image/x-canon-cr2',
  'image/x-canon-cr3' = 'image/x-canon-cr3',
  'image/x-fujifilm-raf' = 'image/x-fujifilm-raf',
  'image/x-icon' = 'image/x-icon',
  'image/x-nikon-nef' = 'image/x-nikon-nef',
  'image/x-olympus-orf' = 'image/x-olympus-orf',
  'image/x-panasonic-rw2' = 'image/x-panasonic-rw2',
  'image/x-sony-arw' = 'image/x-sony-arw',
  'model/gltf-binary' = 'model/gltf-binary',
  'text/calendar' = 'text/calendar',
  'video/3gpp' = 'video/3gpp',
  'video/3gpp2' = 'video/3gpp2',
  'video/MP1S' = 'video/MP1S',
  'video/MP2P' = 'video/MP2P',
  'video/mp2t' = 'video/mp2t',
  'video/mp4' = 'video/mp4',
  'video/mpeg' = 'video/mpeg',
  'video/ogg' = 'video/ogg',
  'video/quicktime' = 'video/quicktime',
  'video/vnd.avi' = 'video/vnd.avi',
  'video/webm' = 'video/webm',
  'video/x-flv' = 'video/x-flv',
  'video/x-m4v' = 'video/x-m4v',
  'video/x-matroska' = 'video/x-matroska',
  'video/x-ms-asf' = 'video/x-ms-asf',
}
