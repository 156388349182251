import { Component, OnInit } from '@angular/core';
import { faGlobeAmericas, faMapMarkerAlt, faMobile, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { HeaderMetaService } from '@mm-mono/modules-client/meta';
import { NgxDialogsService } from '@mm-mono/ngx/dialogs';

@Component({
  selector: 'page-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  public icons = { faMobile, faTimes, faMapMarkerAlt, faGlobeAmericas };
  public features = [
    {
      image: 'assets/views/home/img1.jpg',
      title: 'One Drill, Four Positions',
      desc: 'A drill for every application',
      link: ['/products', 'drills', 'on-grade', 'a-1-series'],
    },
    {
      image: 'assets/views/home/img2.jpg',
      title: 'Concrete Vibrators',
      desc: 'Discover Perfection',
      link: ['/products', 'vibrators'],
    },
    {
      image: 'assets/views/home/img3.jpg',
      title: 'Dust collection',
      desc: 'Silica Dust and the OSHA Standard',
      link: ['/products', 'drills', 'dust-collection', 'dust-collection-system'],
    },
  ];

  constructor(private headerService: HeaderMetaService, private dialogsService: NgxDialogsService) {}

  ngOnInit() {
    this.headerService.setTitle('Home');
  }
}
