import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormGroupComponent } from './Components/form-group/form-group.component';
import { MessagesComponent } from './Components/messages/messages.component';
import { FormControlDirective } from './Directives/form-control.directive';
import { FormValidationDirective } from './Directives/form-validation.directive';
import { BootstrapVersion } from './Enums/BootstrapVersion';
import { NgxBootstrapFormValidationModuleOptions } from './Models/NgBootstrapFormValidationModuleOptions';
import { ErrorMessageService } from './Services/error-message.service';
import { BOOTSTRAP_VERSION, CUSTOM_ERROR_MESSAGES } from './Tokens/tokens';

export { ErrorMessage, FormatErrorFunction } from './Models/error-message';
export { FormControlDirective } from './Directives/form-control.directive';
export { FormValidationDirective } from './Directives/form-validation.directive';
export { MessagesComponent } from './Components/messages/messages.component';
export { FormGroupComponent } from './Components/form-group/form-group.component';
export { BootstrapVersion } from './Enums/BootstrapVersion';
export * from './Tokens/tokens';

@NgModule({
  imports: [CommonModule],
  declarations: [FormValidationDirective, FormGroupComponent, MessagesComponent, FormControlDirective],
  exports: [FormValidationDirective, FormGroupComponent, MessagesComponent, FormControlDirective],
})
export class NgxBootstrapFormValidationModule {
  static forRoot(
    userOptions: NgxBootstrapFormValidationModuleOptions = { bootstrapVersion: BootstrapVersion.Four }
  ): ModuleWithProviders<NgxBootstrapFormValidationModule> {
    return {
      ngModule: NgxBootstrapFormValidationModule,
      providers: [
        {
          provide: CUSTOM_ERROR_MESSAGES,
          useValue: userOptions.customErrorMessages || [],
          multi: true,
        },
        {
          provide: BOOTSTRAP_VERSION,
          useValue: userOptions.bootstrapVersion,
        },
        ErrorMessageService,
      ],
    };
  }
}
