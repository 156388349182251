export { MimeType, MimeTypes } from './mime-types';

export const dayJsSapFormat = 'YYYY-MM-DD HH:mm:ss';
export const dayJsDbFormat = 'YYYY-MM-DD HH:mm:ss';
export const dayJsMongoFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

export const SECOND = 1;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const HALFDAY = HOUR * 12;
export const DAY = HALFDAY * 2;
export const WEEK = DAY * 7;

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
  NULL = '',
}

export interface Pagination {
  searchTerm: string;
  page: number;
  pageSize: number;
  sortParams: SortParams;
}

export interface SortParams {
  sortColumn: string;
  sortDirection: 'DESC' | 'ASC' | SortDirection;
}
