import { computed, Injectable, signal } from '@angular/core';
import { AppwriteDatabasesService, AuthService } from '@appwrite/angular';
import { Query } from '@appwrite/common';
import { AwCollections, AwDatabases } from '@mm-mono/data/appwrite';
import { ShopCartDto, ShopProductDto, UserCartDto } from '@mm-mono/data/dto';
import { SingletonService } from '@mm-mono/modules-client/common';
import { NgxDialogsService } from '@mm-mono/ngx/dialogs';
import { debugStream } from '@mm-mono/utilities';
import { filter, mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TemporaryCartService extends SingletonService {
  public signalCart = signal(new ShopCartDto() as ShopCartDto);
  public getTotalBeforeDiscount = computed(() => this.signalCart()?.items?.reduce((a, b) => a + parseFloat((b.quantity * b.price).toFixed(2)), 0));
  public getTotal = computed(() => this.signalCart()?.items?.reduce((a, b) => a + parseFloat((b.quantity * b.discountPrice).toFixed(2)), 0));
  public getItemsTotal = computed(() => this.signalCart()?.items?.reduce((sum, { quantity }) => sum + quantity, 0));

  constructor(private authService: AuthService, private documentsService: AppwriteDatabasesService, private dialogService: NgxDialogsService) {
    super(TemporaryCartService);
    this.authService.$userData.subscribe(() => this.getCart());
  }

  public removeProduct(item: ShopProductDto) {
    this.dialogService
      .confirm({
        header: 'Confirm Remove',
        message: `Are you sure you want to remove "${item.itemName}" from your cart?`,
        acceptButtonLabel: 'Yes',
        rejectButtonLabel: 'No',
      })
      .pipe(
        filter(Boolean),
        mergeMap(() => this.updateCart({ items: this.signalCart().items.filter((p) => p.itemNumber !== item.itemNumber) }))
      )
      .subscribe();
  }

  public emptyCart() {
    this.dialogService
      .confirm({
        header: 'Confirm Remove',
        message: `Are you sure you want to empty your cart?`,
        acceptButtonLabel: 'Yes',
        rejectButtonLabel: 'No',
      })
      .pipe(
        filter(Boolean),
        mergeMap(() => this.updateCart({ ...this.signalCart(), items: [] }))
      )
      .subscribe();
  }

  public async getCart() {
    const documents = await this.documentsService.listDocuments<UserCartDto>(AwDatabases.default, AwCollections.userCart, [Query.equal('cartType', 'TEMP')]);
    const cart = documents.documents[0];
    this.setCart(cart);
  }

  public getItemTotalBeforeDiscount(item: ShopProductDto) {
    return parseFloat((item.quantity * item.price).toFixed(2));
  }

  public getItemTotalAfterDiscount(item: ShopProductDto) {
    return parseFloat((item.quantity * item.discountPrice).toFixed(2));
  }

  public async addProduct(product: ShopProductDto, quantity: number = 1) {
    this.dialogService
      .prompt({
        header: 'Add Product',
        fields: [{ fieldType: 'number', fieldLabel: 'Quantity', fieldValue: quantity }],
        acceptButtonLabel: 'Add',
        rejectButtonLabel: 'Cancel',
      })
      .pipe(
        filter(Boolean),
        debugStream('prompt2')
        // mergeMap((fields) => fields),
        // find((field) => field.fieldLabel === 'Quantity'),
        // map((field: Field) => [{ ...(product as ShopProductDto), quantity: field.fieldValue }])
      )
      .subscribe(async (promptResult) => {
        const quantityField = promptResult.find((field) => field.fieldLabel === 'Quantity');
        console.log('promptResult', quantityField, product);

        let items = this.signalCart().items;

        const cartItem = items.find((p) => p.itemNumber === product.itemNumber);
        if (cartItem) {
          items = items.map((p) => {
            if (p.itemNumber === product.itemNumber) {
              p.quantity += quantityField.fieldValue;
            }
            return p;
          });
        } else {
          items.push({ ...product, quantity: quantityField.fieldValue });
        }
        this.updateCart({ items });
      });
  }

  public async updateCart(shopCartDto: Partial<ShopCartDto>) {
    const payload = { ...shopCartDto, items: JSON.stringify(shopCartDto.items) };
    const cartReturn = await this.documentsService.updateDocument<UserCartDto>(AwDatabases.default, AwCollections.userCart, this.signalCart().$id, payload);
    this.setCart(cartReturn);
  }

  public updateQuantity(product: ShopProductDto, quantity: number) {
    let items = this.signalCart().items;
    items = items.map((p) => {
      if (p.itemNumber === product.itemNumber) {
        p.quantity = quantity;
      }
      return p;
    });

    this.updateCart({ items });
  }

  private setCart(cart: UserCartDto) {
    cart.items = JSON.parse(cart.items);
    const newCart = new ShopCartDto(cart as unknown as ShopCartDto);
    this.signalCart.set(newCart);
  }
}
