/**
 * Modifies the search parameters of a URL by adding key-value pairs from a payload object.
 *
 * @param {URL} url - The URL to modify.
 * @param {Payload} payload - The payload object containing the key-value pairs to add to the URL's search parameters.
 * @return {URL} The modified URL.
 */
import { Payload } from '@appwrite/common';

export function prepareFormData(formData: FormData, params: Payload) {
  for (const key in params) {
    if (Array.isArray(params[key])) {
      params[key].forEach((value: any) => formData.append(key + '[]', value));
    } else {
      formData.append(key, params[key]);
    }
  }
  return formData;
}
