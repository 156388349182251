import { DocumentBase } from '@appwrite/common';

/**
 * Document
 */
export class AppWriteBaseModel implements Partial<DocumentBase> {
  /**
   * Document ID.
   */
  $id?: string = undefined;
  /**
   * Collection ID.
   */
  $collectionId?: string = undefined;
  /**
   * Database ID.
   */
  $databaseId?: string = undefined;
  /**
   * Document creation date in ISO 8601 format.
   */
  $createdAt?: string = undefined;
  /**
   * Document update date in ISO 8601 format.
   */
  $updatedAt?: string = undefined;
  /**
   * Document permissions. [Learn more about permissions](/docs/permissions).
   */
  $permissions?: string[] = undefined;

  toJSON?() {
    const jsonObj: any = Object.assign({}, this);
    Object.keys(jsonObj).forEach((key) => {
      if (key.indexOf('$') === 0) {
        delete jsonObj[key];
      }
    });
    return jsonObj;
  }
}
