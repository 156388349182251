import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbHighlight } from './highlight';

@NgModule({
  imports: [CommonModule],
  exports: [NgbHighlight],
  declarations: [NgbHighlight],
})
export class NgxHighlightModule {}
