import { Component } from '@angular/core';

@Component({
  selector: 'shop-thanks',
  template: `
    <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div class="text-center">
        <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Thank You for your order!</h1>
        <p class="mt-6 text-base leading-7 text-gray-600">You will receive an email confirmation when your order has been processed.</p>
        <div class="mt-10 flex items-center justify-center gap-x-6">
          <a
            routerLink="/"
            class="bg-accent-600 hover:bg-accent-500 focus-visible:outline-accent-600 rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >Go back home</a
          >
        </div>
      </div>
    </main>
  `,
})
export class ThanksComponent {}
