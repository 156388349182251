import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class MmAuthConfig {
  constructor(private router: Router, private messageService: MessageService) {}

  public onLogout() {
    this.messageService.add({ severity: 'info', summary: 'You have been signed out.' });
    this.router.navigate(['']);
  }

  public onLogin() {
    this.messageService.add({ severity: 'success', summary: 'Welcome!' });
    this.router.navigate(['']);
  }
}
