import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppwriteDatabasesService } from '@appwrite/angular';
import { Document, DocumentList, Query } from '@appwrite/common';
import { AwCollections, AwDatabases } from '@mm-mono/data/appwrite';
import { ArticleDto } from '@mm-mono/data/dto';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class NewsService {
  constructor(public http: HttpClient, private documentsService: AppwriteDatabasesService) {}

  getAll(page: number, limit: number = 10): Observable<DocumentList<ArticleDto>> {
    return from(
      this.documentsService.listDocuments<ArticleDto>(AwDatabases.default, AwCollections.article, [
        Query.equal('visible', true),
        Query.limit(limit),
        Query.offset((page - 1) * limit),
        Query.orderDesc('$createdAt'),
      ])
    );
  }

  getOne(article: string): Observable<Document<ArticleDto>> {
    return from(
      this.documentsService.listDocuments<ArticleDto>(AwDatabases.default, AwCollections.article, [
        Query.equal('visible', true),
        Query.equal('permalink', article),
        Query.limit(100),
      ])
    ).pipe(
      map((d) => d.documents),
      map((d) => d[0])
    );
  }
}
