import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppwriteAngularModule } from '@appwrite/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxBootstrapInputModule } from '@mm-mono/modules-client/ngx/bootstrap-input';
import { NgxBootstrapFormValidationModule } from '@mm-mono/ngx/bootstrap-form-validation';
import { DropdownModule } from 'primeng/dropdown';
import { ImageModule } from 'primeng/image';
import { AppwriteImageComponent, ForgotFormComponent, LoginFormComponent, RegisterFormComponent, ResetFormComponent } from './components';
import { AppwriteDropper } from './dto/appwrite-dropper';

export * from './appwrite-crud.service';
export * from './components';

const ui = [AppwriteImageComponent, AppwriteDropper, LoginFormComponent, ForgotFormComponent, RegisterFormComponent, ResetFormComponent];

interface appwriteModuleOptions {}

@NgModule({
  imports: [
    AppwriteAngularModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxBootstrapFormValidationModule,
    FontAwesomeModule,
    NgxBootstrapInputModule,
    DropdownModule,
    ImageModule,
  ],
  providers: [],
  declarations: [...ui],
  exports: [...ui],
})
export class ModulesAppwriteModule {
  static forRoot(options?: appwriteModuleOptions): ModuleWithProviders<ModulesAppwriteModule> {
    return {
      ngModule: ModulesAppwriteModule,
      providers: [],
    };
  }
}
