import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppwriteAccountService, AppwriteException } from '@appwrite/angular';
import { ForgotPasswordDto } from '@mm-mono/data/appwrite';
import { AuthService } from '@appwrite/angular';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  template: `
    <div class="fixed inset-0 bottom-0 left-0 right-0 top-0 z-[-1] block bg-[url('/assets/views/auth/cleveland-dust-collector.jpg')] bg-cover opacity-30"></div>
    <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <a [routerLink]="['/']" class="flex items-center">
          <minnich-logo [showSubtext]="true" [style.width]="200" class="mx-auto  w-auto"></minnich-logo>
        </a>
      </div>
      <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <h2 class="mb-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Forgot Password</h2>
          <form (submit)="onForgot()" [formGroup]="formData" class="space-y-6">
            <div class="form-group">
              <label class="mb-2 block leading-6 text-gray-900" for="email">Email</label>
              <input formControlName="email" id="email" pInputText type="text" />
            </div>
            <div>
              <button
                class="bg-accent-600 hover:bg-accent-500 focus-visible:outline-accent-600 flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                type="submit"
              >
                Request Password Reset
              </button>
            </div>
            <p class="text-center">Already have an account? <a class="text-accent-600 hover:text-accent-500 font-semibold" routerLink="/login">Log In</a></p>
          </form>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./auth-state.styles.scss'],
})
export class AuthForgotComponent implements OnInit {
  public formData: UntypedFormGroup;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private appwriteAccountService: AppwriteAccountService
  ) {}

  public ngOnInit(): void {
    this.formData = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  public async onForgot() {
    try {
      if (this.formData.valid) {
        const data: ForgotPasswordDto = this.formData.value;
        await this.appwriteAccountService.createRecovery(data.email, 'https://www.minnich-mfg.com/reset');
        this.messageService.add({
          severity: 'success',
          summary: 'If an account with this email exists, a password reset request has been sent to the registered email.',
        });
        this.router.navigate(['/login']);
      }
    } catch (e) {
      if (e instanceof AppwriteException) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: e.message });
      } else if (e instanceof HttpErrorResponse) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: e.error.message });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An unknown error has occurred.' });
      }
      console.error(e);
    }
  }
}
