import { Route, Routes } from '@angular/router';
import { AuthClaimsAgreementsGuard, TeamAndRoles } from '@appwrite/angular';
import { AwTeams } from '@mm-mono/data/appwrite';
import { CheckoutComponent, SavedCartsIndexComponent, ShopComponent, ThanksComponent } from '../components';
import { AccountHistoryComponent } from '../components/account-history.component';

export const checkoutIndexRoute: Route = {
  path: '',
  canActivate: [AuthClaimsAgreementsGuard],
  canActivateChild: [AuthClaimsAgreementsGuard],
  data: { teamId: AwTeams.websiteStoreRoles, roles: ['orders'] } as TeamAndRoles,
  component: CheckoutComponent,
};

export const checkoutThanksRoute: Route = {
  path: 'thanks',
  component: ThanksComponent,
};

export const CHECKOUT_ROUTES: Routes = [checkoutIndexRoute, checkoutThanksRoute];
const shopOrderHistoryRoute: Route = {
  path: 'history',
  component: AccountHistoryComponent,
};
const shopSavedCartsRoute: Route = {
  path: 'carts',
  component: SavedCartsIndexComponent,
};
const shopCheckoutFutureRoutes: Route = {
  path: 'checkout',
  children: CHECKOUT_ROUTES,
};
const shopIndexRoute: Route = {
  path: 'parts',
  component: ShopComponent,
};
const shopAbstractRoute: Route = {
  path: '',
  children: [shopIndexRoute, shopSavedCartsRoute, shopOrderHistoryRoute, shopCheckoutFutureRoutes],
  canActivateChild: [AuthClaimsAgreementsGuard],
  canActivate: [AuthClaimsAgreementsGuard],
  data: { roles: ['shop'] } as TeamAndRoles,
};
export const SHOP_STATES: Routes = [shopAbstractRoute];
