import { Component, OnDestroy, OnInit } from '@angular/core';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Subscription } from 'rxjs';
import { TemporaryCartService } from '../services/temporary-cart.service';

@Component({
  selector: 'cart-summary',
  template: `
    <div class="card">
      <div class="card-body">
        <h3>Cart Summary</h3>
        <div class="table-responsive">
          <table class="table-sm table">
            <thead>
              <tr>
                <th><small>Part Number</small></th>
                <th><small>Name</small></th>
                <th class="text-center"><small>Quantity</small></th>
                <th class="text-center"><small>Discount</small></th>
                <th class="text-center">
                  <small>
                    <fa-icon [icon]="icons.close" role="button" (click)="temporaryCartService.emptyCart()"></fa-icon>
                  </small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of temporaryCartService.signalCart()?.items">
                <td>
                  <small>{{ item.itemNumber }}</small>
                </td>
                <td>
                  <small>{{ item.itemName }}</small>
                </td>
                <td class="text-center">
                  <small>{{ item.quantity }}</small>
                </td>
                <td class="text-center">
                  <small>{{ item.discountPercentage | percent }}</small>
                </td>
                <td class="text-center">
                  <fa-icon [icon]="icons.close" role="button" size="xs" (click)="temporaryCartService.removeProduct(item)"></fa-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h6 class="text-end">
          {{ temporaryCartService?.getItemsTotal() }}
          item<span *ngIf="temporaryCartService?.getItemsTotal() !== 1" class="">s</span>
          will be shipped
        </h6>
        <h6 class="text-end">Total: {{ temporaryCartService?.getTotalBeforeDiscount() | currency }}</h6>
        <h5 class="text-end">After Discount: {{ temporaryCartService?.getTotal() | currency }}</h5>
      </div>
    </div>
  `,
})
export class CartSummaryComponent implements OnInit, OnDestroy {
  public icons = { close: faTrash };
  public subscription = new Subscription();

  constructor(public temporaryCartService: TemporaryCartService) {}

  public ngOnInit(): void {}

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
