import { ModuleWithProviders, NgModule, Optional, Provider, SkipSelf } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { META_OPTIONS } from './header-meta.options';
import { HeaderMetaService } from './header-meta.service';

export * from './header-meta.options';
export * from './header-meta.service';

export interface HeaderMetaModuleOptions {
  headerMetaOptionsProvider?: Provider;
  config?: {
    defaultAppTitle: string;
  };
}

@NgModule({ providers: [Title] })
export class MetaModule {
  constructor(@Optional() @SkipSelf() parentModule: MetaModule) {
    if (parentModule) {
      throw new Error("AngularAppConfigModule is already loaded. It should only be imported in your application's main module.");
    }
  }

  static forRoot(options: HeaderMetaModuleOptions): ModuleWithProviders<MetaModule> {
    return {
      ngModule: MetaModule,
      providers: [
        options.headerMetaOptionsProvider || {
          provide: META_OPTIONS,
          useValue: options.config,
        },
        HeaderMetaService,
      ],
    };
  }
}
