import { Injectable } from '@angular/core';
import { BuildDetails } from './ngx-app-version-checker.module';

@Injectable()
export class BuildDetailsService {
  public buildDetails: BuildDetails;

  constructor() {
    this.buildDetails = new BuildDetails();
  }
}
