export enum SQLCommands {
  getFreight = 'getFreight',
  getShipping = 'getShipping',
  getParts = 'getParts',
  getCompany = 'getCompany',
  getAddresses = 'getAddresses',
  getContacts = 'getContacts',
  getOrderHistory = 'getOrderHistory',
  sql = 'sql',
}
