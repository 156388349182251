<div class="lg:pt-22 container pb-8 pt-20 sm:pt-24">
  <div class="xl:grid xl:grid-cols-8 xl:gap-8">
    <div class="lg:col-span-2">
      <minnich-logo [style.width]="200" [textFill]="'white'"></minnich-logo>
    </div>
    <div class="grid grid-cols-2 gap-8 xl:col-span-4">
      <div class="md:grid md:grid-cols-2 md:gap-8">
        <div>
          <h3 class="text-sm font-semibold leading-6 text-white">Products</h3>
          <ul class="mt-6 space-y-4" role="list">
            <li>
              <a class="text-sm leading-6 text-gray-300 hover:text-white" routerLink="/products/drills">Drills</a>
            </li>
            <li>
              <a class="text-sm leading-6 text-gray-300 hover:text-white" routerLink="/products/vibrators">Vibrators</a>
            </li>
            <!--            <li>-->
            <!--              <a class="text-sm leading-6 text-gray-300 hover:text-white" routerLink="/used-inventory">Used Inventory / Old Stock</a>-->
            <!--            </li>-->
          </ul>
        </div>
        <div class="mt-10 md:mt-0">
          <h3 class="text-sm font-semibold leading-6 text-white">Company</h3>
          <ul class="mt-6 space-y-4" role="list">
            <li>
              <a class="text-sm leading-6 text-gray-300 hover:text-white" routerLink="/about">About</a>
            </li>
            <li>
              <a class="text-sm leading-6 text-gray-300 hover:text-white" routerLink="/news">News</a>
            </li>
            <li>
              <a class="text-sm leading-6 text-gray-300 hover:text-white" routerLink="/videos">Videos</a>
            </li>
            <li>
              <a class="text-sm leading-6 text-gray-300 hover:text-white" routerLink="/meet-the-team">Meet The Team</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="md:grid md:grid-cols-2 md:gap-8">
        <div>
          <h3 class="text-sm font-semibold leading-6 text-white">Support</h3>
          <ul class="mt-6 space-y-4" role="list">
            <li>
              <a class="text-sm leading-6 text-gray-300 hover:text-white" routerLink="/contact">Contact Us</a>
            </li>
            <li>
              <a class="text-sm leading-6 text-gray-300 hover:text-white" routerLink="/dealers">Find A Dealer</a>
            </li>
          </ul>
        </div>
        <div class="mt-10 md:mt-0">
          <h3 class="text-sm font-semibold leading-6 text-white">Legal</h3>
          <ul class="mt-6 space-y-4" role="list">
            <li>
              <a [routerLink]="['/legal', 'terms']" class="text-sm leading-6 text-gray-300 hover:text-white">Terms</a>
            </li>
            <li>
              <a [routerLink]="['/legal', 'privacy']" class="text-sm leading-6 text-gray-300 hover:text-white">Privacy Policy</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="mt-10 xl:col-span-2 xl:mt-0">
      <h3 class="text-sm font-semibold leading-6 text-white">Subscribe to our newsletter</h3>
      <p class="mt-2 text-sm leading-6 text-gray-300">The latest news, articles, and resources, sent to your inbox weekly.</p>
      <form (submit)="submit()" [formGroup]="form" autocomplete="off" class="mt-6 sm:flex sm:max-w-md">
        <div class="form-group">
          <label class="sr-only" for="email-address">Email address</label>
          <input
            class="focus:ring-accent-500 w-full min-w-0 appearance-none rounded-md border-0 bg-white/5 px-3 py-1.5 text-base text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset sm:w-64 sm:text-sm sm:leading-6 xl:w-full"
            id="email-address"
            name="email-address"
            formControlName="email"
            placeholder="Enter your email"
            required
            type="email"
          />
        </div>
        <div class="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
          <button
            class="bg-accent-500 hover:bg-accent-400 focus-visible:outline-accent-500 flex w-full items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            type="submit"
          >
            Subscribe
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="mt-16 border-t border-white/10 pt-8 sm:mt-20 md:flex md:items-center md:justify-between lg:mt-24">
    <p class="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
      &copy; 2024 Minnich Manufacturing, Inc. All rights reserved.
      <br />
      Minnich and The Hornet are trademarks of Minnich Manufacturing, Inc.
    </p>
  </div>
</div>
