import { Component } from '@angular/core';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRight, faEye, faSave, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { NgxDialogsService } from '@mm-mono/ngx/dialogs';
import { TemporaryCartService } from '../services';
import { SavedCartService } from '../services/saved-cart.service';

@Component({
  selector: 'app-saved-carts-index',
  template: `
    <h1 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Shopping Cart</h1>
    <div class="mt-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16">
      <section aria-labelledby="cart-heading" class="lg:col-span-7">
        <h2 class="sr-only" id="cart-heading">Items in your shopping cart</h2>

        <ul class="divide-y divide-gray-200 border-b border-t border-gray-200" role="list">
          <li *ngFor="let item of cart()?.items" class="flex py-6 sm:py-10">
            <div class="flex-shrink-0">
              <img
                [src]="'https://parts.minnich-mfg.com/parts/' + item.itemNumber + '.jpg'"
                class="h-20 w-20 rounded-md object-cover object-center "
                onerror="this.onerror=null;this.src='https://parts.minnich-mfg.com/parts/unknown.jpg';"
              />
            </div>

            <div class="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
              <div class="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                <div>
                  <div class="flex justify-between">
                    <h3 class="text-sm">
                      <a class="font-medium text-gray-700 hover:text-gray-800" href="#">{{ item.itemNumber }}</a>
                    </h3>
                  </div>
                  <div class="mt-1 flex text-sm">
                    <p class="text-gray-500">{{ item.itemName }}</p>
                  </div>
                  <p class="mt-1 text-sm font-medium text-gray-900">
                    @if (item.discountPercentage) {
                    <span class="hidden md:inline">
                      <span class="line-through">{{ item.price * item.quantity | currency }}</span> / {{ item.discountPrice * item.quantity | currency }}</span
                    >
                    <small class="ml-1 text-neutral-500">{{ item.discountPercentage | percent }}</small>
                    } @else {
                    <span class="hidden md:inline">{{ item.price | currency }}</span>
                    }
                  </p>
                </div>

                <div class="mt-4 sm:mt-0 sm:pr-9">
                  <p-inputNumber
                    (ngModelChange)="temporaryCartService.updateQuantity(item, $event)"
                    [min]="1"
                    [ngModelOptions]="{ updateOn: 'blur' }"
                    [ngModel]="item.quantity"
                    [showButtons]="true"
                    inputId="minmax-buttons"
                    mode="decimal"
                  ></p-inputNumber>
                  <div class="absolute right-0 top-0">
                    <button (click)="temporaryCartService.removeProduct(item)" class="-m-2 inline-flex p-2 text-gray-400 hover:text-gray-500" type="button">
                      <fa-icon [icon]="icons.faClose"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </section>

      <!-- Order summary -->
      <section aria-labelledby="summary-heading" class="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8">
        <h2 class="text-lg font-medium text-gray-900" id="summary-heading">Order summary</h2>

        <dl class="mt-6 space-y-4">
          <div class="flex items-center justify-between">
            <dt class="text-sm text-gray-600">Total before discount</dt>
            <dd class="text-sm font-medium text-gray-900">{{ temporaryCartService.getTotalBeforeDiscount() | currency }}</dd>
          </div>

          <div class="flex items-center justify-between border-t border-gray-200 pt-4">
            <dt class="text-base font-medium text-gray-900">Order total <sup>†</sup></dt>
            <dd class="text-base font-medium text-gray-900">{{ temporaryCartService.getTotal() | currency }}</dd>
          </div>
        </dl>

        <div class="mt-6">
          <button
            [disabled]="!cart()?.items?.length"
            class="bg-accent-600 hover:bg-accent-700 focus:ring-accent-500 w-full rounded-md border border-transparent px-4 py-3 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50"
            routerLink="/portal/shop/checkout"
            type="submit"
          >
            Checkout
          </button>
        </div>
        <div class="mt-6">
          <small><sup>†</sup> Final cost may change after tax, discounts, and shipping have been applied in our system.</small>
        </div>
      </section>
    </div>
  `,
})
export class SavedCartsIndexComponent {
  public icons = { faTrashAlt, faArrowRight, faEye, faSave, faClose };
  public cart = this.temporaryCartService.signalCart.asReadonly();

  constructor(public temporaryCartService: TemporaryCartService, public savedCartService: SavedCartService, private dialogService: NgxDialogsService) {}

  // viewCart(cart: ShopCartDto) {
  //   const config: ModalOptions<any> = {
  //     /*class: 'modal-xl', initialState: { cartId: cart.$id } */
  //   };
  //   this.dialogService.component(SavedCartViewModal, config).subscribe((res) => console.log({ res }));
  // }

  // saveAs() {
  //   this.dialogService
  //     .prompt(
  //       {
  //         header: 'Save As',
  //         fields: [
  //           { fieldType: 'text', fieldLabel: 'Name' },
  //           { fieldType: 'text', fieldLabel: 'Description' },
  //         ],
  //         acceptButtonLabel: 'Save',
  //         rejectButtonLabel: 'Cancel',
  //       },
  //       {
  //         // class: 'modal-sm',
  //       }
  //     )
  //     .pipe(
  //       catchError(() => EMPTY),
  //       withLatestFrom(of(this.temporaryCartService.cart)),
  //       map(([fields, cart]) => {
  //         const newCart = new ShopCartDto();
  //         newCart.$id = null;
  //         newCart.name = fields.find((f) => (f.fieldLabel = 'Name')).fieldValue;
  //         newCart.description = fields.find((f) => (f.fieldLabel = 'Description')).fieldValue;
  //         newCart.items = cart.items;
  //         newCart.cartType = CartType.LIST;
  //         return newCart;
  //       }),
  //       mergeMap((newCart) => this.temporaryCartService.createCart(newCart))
  //     )
  //     .subscribe();
  // }
}
