import { Attribute, Component, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { SortDirection } from '@mm-mono/common';
import { Subscription } from 'rxjs';
import { NgxTableService } from '../ngx-table.service';

@Component({
  selector: 'th[ngTh]',
  template: ` <ng-content></ng-content>`,
  styleUrls: ['./th.component.scss'],
})
export class ThComponent implements OnInit, OnDestroy {
  @Input() name: string;

  private sortColumn: string;
  private direction: SortDirection = SortDirection.NULL;

  @HostBinding('class.asc') get asc() {
    return this.name === this.sortColumn && this.direction === SortDirection.ASC;
  }

  @HostBinding('class.desc') get desc() {
    return this.name === this.sortColumn && this.direction === SortDirection.DESC;
  }

  private subscription: Subscription = new Subscription();

  constructor(private elm: ElementRef<HTMLTableHeaderCellElement>, @Optional() @Attribute('fit') public fit: any, public ngTableService: NgxTableService<any>) {
    this.fit = this.fit !== null;
  }

  @HostListener('click') rotate() {
    if (!this.name) {
      return;
    }
    const rotateKeys = { asc: SortDirection.DESC, desc: SortDirection.NULL, '': SortDirection.ASC };
    this.direction = rotateKeys[this.direction];
    this.ngTableService.sortBy({ column: this.name, direction: this.direction });
  }

  public ngOnInit(): void {
    if (this.fit) this.elm.nativeElement.setAttribute('width', '1%');

    this.subscription.add(
      this.ngTableService.sortParams.subscribe((column) => {
        this.sortColumn = column.sortColumn;
        if (this.name !== column.sortColumn) this.direction = SortDirection.NULL;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
