import { Component, Input, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EMPTY, of } from 'rxjs';

type FieldType = 'select' | 'color' | 'date' | 'password' | 'email' | 'number' | 'tel' | 'text' | 'url' | 'time';

export type Fields = Array<Field>;

export interface Field {
  fieldLabel: string;
  fieldType: FieldType;
  fieldValue?: any;
  fieldOptions?: any;
}

export interface PromptModalOptions {
  message?: string;
  fields: Array<Field>;
  header?: string;
  acceptButtonLabel?: string;
  rejectButtonLabel?: string;
  rejectButtonValue?: any;
}

@Component({
  selector: 'prompt-modal-component',
  template: `
    <div class="mt-2">
      @if (message) {
      <p class="text-gray-500">{{ message }}</p>
      } @for (field of fields; track field) {
      <div class="mt-2.5">
        @if (field.fieldType === 'select') {
        <label [for]="field.fieldLabel" class="block text-sm font-medium leading-6 text-gray-900">Country</label>
        <div class="mt-2">
          <select
            [(ngModel)]="field.fieldValue"
            [name]="field.fieldLabel"
            [id]="field.fieldLabel"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          >
            <option selected>Choose...</option>
            <option *ngFor="let option of field.fieldOptions" [value]="option">{{ option }}</option>
          </select>
        </div>
        } @else {
        <label [for]="field.fieldLabel" class="block text-sm font-medium leading-6 text-gray-900">{{ field.fieldLabel }}</label>
        <div class="mt-2">
          <input
            [(ngModel)]="field.fieldValue"
            [type]="field.fieldType"
            [name]="field.fieldLabel"
            [id]="field.fieldLabel"
            class="focus:ring-accent-600 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
          />
        </div>
        }
      </div>
      }
    </div>
    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <button
        (click)="accept()"
        class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
        type="button"
      >
        {{ acceptButtonLabel }}
      </button>
      <button
        (click)="reject()"
        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        type="button"
      >
        {{ rejectButtonLabel }}
      </button>
    </div>
  `,
})
export class PromptDialog implements OnInit {
  @Input() message: string;
  @Input() title: string;
  @Input() acceptButtonLabel: string;
  @Input() rejectButtonLabel: string;
  @Input() rejectButtonValue = undefined;
  @Input() fields: Field[];

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  public ngOnInit(): void {
    console.log(`Opened ${this.constructor.name} with`, this.config);
    Object.keys(this.config?.data).forEach((k) => (this[k] = this.config?.data?.[k] ?? this[k]));
  }

  public accept() {
    for (const field of this.fields) {
      if (field.fieldType === 'number') {
        field.fieldValue = parseFloat(field.fieldValue);
      }
    }

    this.ref.close(this.fields);
  }

  public reject() {
    this.ref.close(undefined);
  }
}
