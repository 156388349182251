export function trimCharacter(string: string = '', character: string = '') {
  if (character === ']') {
    character = '\\]';
  }
  if (character === '\\') {
    character = '\\\\';
  }
  const regexPattern = new RegExp('^[' + character + ']+|[' + character + ']+$', 'g');
  return string.replace(regexPattern, '');
}
