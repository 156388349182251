<orange-bar></orange-bar>
<green-bar id="navbarSupportedContent"></green-bar>
<nav class="border-gray-200 bg-neutral-50 py-2.5">
  <div class="container flex flex-wrap items-center justify-between">
    <a [routerLink]="['/']" class="flex w-36 items-center xl:w-48">
      <minnich-logo width="100%"></minnich-logo>
    </a>
    <div class="flex items-center lg:order-2">
      <div class="relative">
        <div class="mb relative inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:pr-0">
          <!--          <button-->
          <!--            class="relative flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2"-->
          <!--            routerLink="/shop"-->
          <!--            type="button"-->
          <!--          >-->
          <!--            <span class="absolute -inset-1.5"></span>-->
          <!--            <span class="sr-only">Go to shop</span>-->
          <!--            <fa-icon [icon]="icons.faStore" class="inline-block h-6 w-6"></fa-icon>-->
          <!--          </button>-->
          <!--          <button-->
          <!--            class="relative ml-3 flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2"-->
          <!--            routerLink="/shop/carts"-->
          <!--            type="button"-->
          <!--          >-->
          <!--            <span class="absolute -inset-1.5"></span>-->
          <!--            <span class="sr-only">View Cart</span>-->
          <!--            <fa-icon [icon]="icons.faShoppingCart" class="inline-block h-6 w-6"></fa-icon>-->
          <!--          </button>-->

          <!-- Profile dropdown -->
          <div class="relative ml-3">
            <div>
              @if(user){
              <button
                (click)="op.toggle($event)"
                aria-expanded="false"
                aria-haspopup="true"
                class="relative m-0 flex rounded-full bg-white p-0 text-sm focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2"
                id="user-menu-button"
                type="button"
              >
                <span class="absolute -inset-1.5"></span>
                <span class="sr-only">Open user menu</span>
                <img [src]="avatarService.getInitials(user?.name)" alt="" class="h-8 w-8 rounded-full" />
              </button>
              }
            </div>
            <p-overlayPanel #op styleClass="p-0">
              <ng-template pTemplate="content">
                <a
                  class="-mx-2 block w-48 rounded-md px-2 py-1 text-sm text-gray-700 hover:bg-neutral-200"
                  routerLink="/portal/account/profile"
                  id="user-menu-item-0"
                  role="menuitem"
                  tabindex="-1"
                  >My Account</a
                >
                <a
                  class="-mx-2 block w-48 rounded-md px-2 py-1 text-sm text-gray-700 hover:bg-neutral-200"
                  routerLink="/portal/account/history"
                  id="user-menu-item-1"
                  role="menuitem"
                  tabindex="-1"
                  >Order History</a
                >
                <hr class="border-1 my-2 border border-solid" />
                <a
                  class="-mx-2 block w-48 cursor-pointer rounded-md px-2 py-1 text-sm text-gray-700 hover:bg-neutral-200"
                  (click)="logout()"
                  id="user-menu-item-2"
                  role="menuitem"
                  tabindex="-1"
                  >Sign out</a
                >
              </ng-template>
            </p-overlayPanel>
          </div>
        </div>
      </div>
      <button
        aria-controls="mobile-menu-2"
        aria-expanded="true"
        class="ml-1 inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-neutral-100 focus:outline-none focus:ring-2 focus:ring-gray-200 lg:hidden dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        data-collapse-toggle="mobile-menu-2"
        type="button"
        (click)="toggle()"
      >
        <span class="sr-only">Open main menu</span>
        <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            clip-rule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            fill-rule="evenodd"
          ></path>
        </svg>
        <svg class="hidden h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            clip-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            fill-rule="evenodd"
          ></path>
        </svg>
      </button>
    </div>
    <div class="w-full items-center justify-between lg:order-1 lg:flex lg:w-auto" id="mobile-menu-2">
      <div
        [class.hidden]="isCollapsed || currentWindowWidth > 1024"
        class="mt-4 flex-col justify-between space-x-4 font-medium uppercase lg:mt-0 lg:flex lg:flex-row lg:items-center lg:space-x-4 xl:space-x-6"
      >
        <a
          aria-current="page"
          class="block rounded bg-transparent py-2 pl-4 text-base font-semibold text-neutral-700 lg:py-12 lg:pl-0"
          routerLink="/products/drills"
          routerLinkActive="underline underline-offset-8 decoration-2"
          >Drills</a
        >

        <a
          class="block rounded bg-transparent py-2 text-base font-semibold text-neutral-700 lg:py-12"
          routerLink="/products/vibrators"
          routerLinkActive="underline underline-offset-8 decoration-2"
          >Vibrators</a
        >

        <a
          class="block rounded bg-transparent py-2 text-base font-semibold text-neutral-700 lg:py-12"
          routerLink="/videos"
          routerLinkActive="underline underline-offset-8 decoration-2"
          >Videos</a
        >

        <a
          class="block rounded bg-transparent py-2 text-base font-semibold text-neutral-700 lg:py-12"
          routerLink="/news"
          routerLinkActive="underline underline-offset-8 decoration-2"
          >News</a
        >

        <a
          class="block rounded bg-transparent py-2 text-base font-semibold text-neutral-700 lg:py-12"
          routerLink="/about"
          routerLinkActive="underline underline-offset-8 decoration-2"
          >About</a
        >
        <a
          class="block rounded bg-transparent py-2 text-base font-semibold text-neutral-700 lg:py-12"
          routerLink="/dealers"
          routerLinkActive="underline underline-offset-8 decoration-2"
          >Dealers</a
        >
        <a
          class="block rounded bg-transparent py-2 text-base font-semibold text-neutral-700 lg:py-12"
          routerLink="/contact"
          routerLinkActive="underline underline-offset-8 decoration-2"
          >Contact</a
        >

        <a class="mx-2 my-2 block rounded border border-neutral-400 bg-transparent text-base font-semibold text-neutral-700 lg:my-0 lg:py-2"></a>
        @if (user) {
        <a
          class="block rounded bg-transparent py-2 text-base font-semibold text-neutral-700 lg:py-12"
          routerLink="/portal/account/profile"
          routerLinkActive="underline underline-offset-8 decoration-2"
          >Portal</a
        >
        } @else {
        <a
          class="block rounded bg-transparent py-2 text-base font-semibold text-neutral-700 lg:py-12"
          routerLink="/login"
          routerLinkActive="underline underline-offset-8 decoration-2"
          >Dealer Login</a
        >
        }
      </div>
    </div>
  </div>
</nav>
