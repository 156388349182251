import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { NgxBootstrapFormValidationModule } from '@mm-mono/ngx/bootstrap-form-validation';
import { NgxDialogsService } from '@mm-mono/ngx/dialogs';
import { defer, EMPTY, iif } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { LogoComponent } from '../logo/logo.component';

interface MailChimpResponse {
  result: string;
  msg: string;
}

@Component({
  selector: 'footer',
  standalone: true,
  templateUrl: './footer.component.html',
  imports: [LogoComponent, RouterLink, FormsModule, ReactiveFormsModule, NgxBootstrapFormValidationModule],
})
export class FooterComponent implements OnInit {
  public form: UntypedFormGroup;
  public year = new Date().getFullYear();

  constructor(private fb: FormBuilder, private dialogsService: NgxDialogsService, private http: HttpClient) {}

  public ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  public submit() {
    console.log(this.form?.value);
    if (this.form?.valid) {
      const params = new HttpParams()
        .set('u', 'a7b6d5bdddc6420b5eeb4b845')
        .set('id', 'cf84fe0061')
        .set('ht', '81c95eb1b54c5597cff879ee94cd30de0bfedc79:MTY4NTExMjM0Ny44NTk3')
        .set('mc_signupsource', 'hosted')
        .set('MERGE0', this.form.get('email').value);

      const mailChimpUrl = `https://minnich-mfg.us1.list-manage.com/subscribe/post-json?` + params.toString();

      this.http
        .jsonp<MailChimpResponse>(mailChimpUrl, 'c')
        .pipe(
          catchError((error) => this.dialogsService.alert({ message: error.msg, header: 'Error' }).pipe(mergeMap(() => EMPTY))),
          mergeMap((d) =>
            iif(
              () => d?.result !== 'error',
              defer(() => this.dialogsService.alert({ message: d.msg })),
              defer(() => this.dialogsService.alert({ message: d.msg, header: 'Error' }).pipe(mergeMap(() => EMPTY)))
            )
          )
        )
        .subscribe();
    }
  }
}
