import { Component, OnInit } from '@angular/core';
import { AppwriteFunctionsService } from '@appwrite/angular';
import { Execution } from '@appwrite/common';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { SQLCommands } from '@mm-mono/cloud/sql';
import { SortDirection } from '@mm-mono/common';
import { AwFunctions } from '@mm-mono/data/appwrite';
import { rxJsonParse } from '@mm-mono/utilities';
import { from, Observable, pluck } from 'rxjs';
import { TemporaryCartService } from '../services';

@Component({
  selector: 'shop-index',
  template: `
    <h1 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Search for Parts</h1>
    <p class="mt-2 text-sm text-gray-500">Search our inventory for parts and equipment.</p>

    <ngx-table
      [defaultSortParams]="{ sortColumn: '', sortDirection: null }"
      [fn]="fn"
      [requiresInput]="true"
      class="my-6 block"
      tableClass="min-w-full divide-y divide-gray-300"
      tbodyClass="divide-y divide-gray-200 bg-white"
    >
      <ng-template #tfoot></ng-template>
      <ng-template #thead>
        <tr>
          <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" ngTh width="1%">Image</th>
          <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" name="itemNumber" ngTh>Part Number</th>
          <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" name="itemName" ngTh>Name</th>
          <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" name="inStock" ngTh>In Stock</th>
          <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" name="price" ngTh>Price</th>
          <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" name="discountPercentage" ngTh>Discount</th>
          <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" name="discountPrice" ngTh>Discounted Price</th>
          <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" ngTh width="1%"></th>
        </tr>
      </ng-template>
      <ng-template #tbody let-rows>
        <tr *ngFor="let product of rows">
          <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
            <img
              height="40"
              onerror="this.onerror=null;this.src='https://parts.minnich-mfg.com/parts/unknown.jpg?w=100';"
              src="https://parts.minnich-mfg.com/parts/{{ product.itemNumber }}.jpg?w=100"
            />
          </td>
          <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500" ngTd>
            {{ product.itemNumber }}
          </td>
          <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500" ngTd>
            {{ product.itemName }}
          </td>
          <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500" ngTd>
            {{ product.inStock | number }}
          </td>
          <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500" ngTd>{{ product.price | currency }}/{{ product.uom }}</td>
          <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500" ngTd>
            {{ product.discountPercentage | percent }}
          </td>
          <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500" ngTd>
            {{ product.discountPrice | currency }}
          </td>
          <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500" nowrap>
            <button (click)="temporaryCartService.addProduct(product)" class="btn btn-success">
              <fa-icon [icon]="icons.shoppingCart"></fa-icon>
            </button>
          </td>
        </tr>
      </ng-template>
    </ngx-table>
  `,
})
export class ShopComponent implements OnInit {
  public icons = { shoppingCart: faPlus };
  public url: string;
  public fn: (page: number, pageSize: number, searchTerm: string, sortParams: any) => Observable<Execution>;

  constructor(public temporaryCartService: TemporaryCartService, private appwriteFunctionsService: AppwriteFunctionsService) {}

  public ngOnInit(): void {
    this.fn = (page, pageSize, searchTerm, sortParams) => {
      const parameters = {
        page: page || 1,
        pageSize: pageSize || 5,
        searchTerm: searchTerm || '',
        sortColumn: sortParams.sortColumn || '',
        sortDirection: sortParams.sortDirection || SortDirection.NULL,
      };
      return from(
        this.appwriteFunctionsService.createExecution(AwFunctions.msSql, JSON.stringify({ command: SQLCommands.getParts, parameters: parameters }))
      ).pipe(rxJsonParse('responseBody'), pluck('responseBody', 'data'));
    };
  }
}
