import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderMetaService } from '@mm-mono/modules-client/meta';
import { Subscription } from 'rxjs';

export interface ErrorParams {
  title: string;
  code: number;
  message: string;
  from: null;
  to: null;
}

@Component({
  selector: 'errors-component',
  templateUrl: './errors.component.html',
})
export class ErrorsComponent implements OnInit, OnDestroy {
  public title: string;
  public code: number;
  public message: string;
  private subscription: Subscription;

  constructor(private headerService: HeaderMetaService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.subscription = this.route.queryParams.subscribe((queryParams) => {
      this.code = queryParams.code || 404;
      this.message = queryParams.message || 'Page not found';
      this.headerService.setStatusCode(this.code);
    });
  }

  public ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
