import { Component, Input, OnInit } from '@angular/core';
import { faPlus, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { ShopCartDto } from '@mm-mono/data/dto';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TemporaryCartService } from '../services';
import { SavedCartService } from '../services/saved-cart.service';

export interface SaveEditCartModel {
  name: string;
  cart: ShopCartDto;
  tempCart: ShopCartDto;
  description: string;
}

@Component({
  selector: 'modal-content',
  template: `
    <form (ngSubmit)="save()" #saveAsForm="ngForm">
      <div class="modal-header">
        <h4 class="modal-title">Viewing {{ savedCart?.name }}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="cancel(null)"></button>
      </div>
      <div class="modal-body">
        <div class="table-responsive ng-scope">
          <table class="table">
            <thead class="thead-light">
              <tr>
                <th></th>
                <th>Part</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total</th>
                <th>Discount</th>
                <th>After Discount</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of savedCart?.items; let i = index">
                <td>
                  <img
                    [src]="'https://parts.minnich-mfg.com/parts/' + row.itemNumber + '.jpg?w=100'"
                    onerror="this.onerror=null;this.src='https://parts.minnich-mfg.com/parts/unknown.jpg?w=100';"
                    height="40"
                    alt=""
                  />
                </td>
                <td class="text-nowrap">
                  <strong>{{ row.itemNumber }}</strong>
                  <br />
                  <small>{{ row.itemName }}</small>
                </td>
                <td>{{ row.price | currency }}</td>
                <td>
                  <input [ngModelOptions]="{ standalone: true }" type="number" class="form-control form-control-sm" [(ngModel)]="row.quantity" />
                </td>
                <td>{{ temporaryCartService.getItemTotalBeforeDiscount(row) | currency }}</td>
                <td>{{ row.discountPercentage | percent }}</td>
                <td>{{ temporaryCartService.getItemTotalAfterDiscount(row) | currency }}</td>

                <td class="text-nowrap">
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        tooltip="Add this item to your temporary cart"
                        (click)="temporaryCartService.addProduct(row, row.quantity)"
                      >
                        <fa-icon [icon]="icons.plus"></fa-icon>
                      </button>
                    </li>
                    <li class="list-inline-item">
                      <button
                        type="button"
                        class="btn btn-danger btn-sm"
                        (click)="savedCart.items.splice(i, 1)"
                        tooltip="Remove this item from your saved cart"
                      >
                        <fa-icon class="" [icon]="icons.trash"></fa-icon>
                      </button>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" [disabled]="!saveAsForm.form.valid">Save</button>
        <button type="button" class="btn btn-secondary" (click)="cancel(null)">Cancel</button>
      </div>
    </form>
  `,
})
export class SavedCartViewModal implements OnInit {
  @Input() public cartId = null;
  public icons = { trash: faTrashAlt, plus: faPlus };
  public savedCart: ShopCartDto;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public shopCartService: SavedCartService,
    public temporaryCartService: TemporaryCartService
  ) {}

  public ngOnInit(): void {
    Object.keys(this.config?.data).forEach((k) => (this[k] = this.config?.data?.[k] ?? this[k]));
    this.savedCart = this.shopCartService.savedCarts.find((c) => c.$id === this.cartId);
  }

  cancel(cancel: null) {
    this.ref.close();
  }

  save() {
    this.shopCartService.updateCart(this.savedCart, this.savedCart).subscribe(() => {
      this.ref.close();
    });
  }
}
