import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppwriteDatabasesService } from '@appwrite/angular';
import { ID } from '@appwrite/common';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { AwCollections, AwDatabases } from '@mm-mono/data/appwrite';
import { OrderDto, ShopProductDto } from '@mm-mono/data/dto';
import { CheckoutFormClass } from '@mm-mono/modules/store-front/common';
import { NgxDialogsService } from '@mm-mono/ngx/dialogs';
import { OSHP, UFD1 } from '@sap/common';
import { MessageService } from 'primeng/api';
import { filter, Observable } from 'rxjs';
import { CheckoutService, TemporaryCartService } from '../services';

@Component({
  selector: 'checkout-index',
  templateUrl: `checkout.component.html`,
})
export class CheckoutComponent {
  public icons = { spinner: faSpinner };
  public checkoutForm: CheckoutFormClass = new CheckoutFormClass();

  // data

  public loading: boolean = false;
  public assign: typeof Object.assign = Object.assign;
  public $addresses = this.checkoutService.getAddresses();
  public $freight: Observable<UFD1[]> = this.checkoutService.getFreight();
  public $shipping: Observable<OSHP[]> = this.checkoutService.getShipping();

  constructor(
    public shopCartService: TemporaryCartService,
    private checkoutService: CheckoutService,
    private router: Router,
    private messageService: MessageService,
    private documentsService: AppwriteDatabasesService,
    private dialogService: NgxDialogsService
  ) {}

  public edit(item: ShopProductDto) {
    this.dialogService
      .prompt({
        header: 'Edit Product',
        fields: [{ fieldType: 'number', fieldLabel: 'Quantity', fieldValue: item.quantity }],
        acceptButtonLabel: 'Update',
        rejectButtonLabel: 'Cancel',
      })
      .pipe(filter(Boolean))
      .subscribe(async (res) => {
        this.shopCartService.updateQuantity(item, res[0].fieldValue);
      });
  }

  async checkout() {
    try {
      if (this.loading) {
        return;
      }

      const payload = { ...this.checkoutForm, cart: this.shopCartService.signalCart() };

      await this.documentsService.createDocument<OrderDto>(AwDatabases.default, AwCollections.order, ID.unique(), {
        orderData: JSON.stringify(payload),
      });
      this.loading = false;
      this.messageService.add({ severity: 'success', summary: `Your order has been submitted!` });
      this.router.navigate(['/portal/shop/checkout/thanks']);
    } catch (e) {
      this.loading = false;
      console.log(e);
    }
  }
}
