import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { YoutubeDirective } from './youtube-data/youtube.directive';

@NgModule({
  declarations: [YoutubeDirective],
  exports: [YoutubeDirective],
  imports: [CommonModule],
})
export class NgxPipesModule {}
