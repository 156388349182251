import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { BuildDetailsHttpService } from './build-details.http.service';
import { BuildDetailsService } from './build-details.service';
import { UpToDateBuildService } from './up-to-date.service';

export * from './up-to-date.service';
export * from './build-details.http.service';
export * from './build-details.service';

export function fetchBuildDetails(buildDetailsService: BuildDetailsHttpService) {
  return () => buildDetailsService.fetchBuildDetails();
}

export class BuildDetails {
  buildDate: number;
}

@NgModule({
  imports: [HttpClientJsonpModule, HttpClientModule],
  declarations: [],
  exports: [],
})
export class NgxAppVersionCheckerModule {
  constructor(@Optional() @SkipSelf() parentModule: NgxAppVersionCheckerModule) {
    if (parentModule) {
      throw new Error("AngularAppConfigModule is already loaded. It should only be imported in your application's main module.");
    }
  }

  static forRoot(): ModuleWithProviders<NgxAppVersionCheckerModule> {
    return {
      ngModule: NgxAppVersionCheckerModule,
      providers: [BuildDetailsService, BuildDetailsHttpService, UpToDateBuildService],
    };
  }
}
