export * from './aw-collections';
export * from './aw-buckets';
export * from './aw-databases';
export * from './aw-teams';
export * from './aw-functions';
export * from './aw-roles';

export enum RegistrationSource {
  'minnichmonitor' = 'minnichmonitor',
  'website' = 'website',
  'dashboard' = 'dashboard',
}
