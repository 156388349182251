import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BypassSecurityTrust, BypassSecurityTrustHtml } from './bypass-security-trust';
import { JsonParsePipe } from './json-parse.pipe';
import { MimeFontawesomePipe } from './mime-font-awesome.pipe';

const common = [JsonParsePipe, MimeFontawesomePipe, BypassSecurityTrust, BypassSecurityTrustHtml];

@NgModule({
  declarations: [...common],
  imports: [CommonModule],
  exports: [...common],
})
export class ModulesClientCommonModule {}
