import { ShopCartDto } from '@mm-mono/data/dto';
import { BillingShippingFormDto, FreightDto, ShippingMethodDto } from '../dto';
import { ShippingDto } from '../dto/shipping.dto';

export interface CheckoutFormClass {}

export class CheckoutFormClass {
  public billingForm: BillingShippingFormDto = new BillingShippingFormDto();
  public shippingForm: BillingShippingFormDto = new BillingShippingFormDto();
  public shippingMethod: ShippingMethod = new ShippingMethod();
  public poNumber: string = '';
  public cart: ShopCartDto;
}

export class ShippingMethod implements ShippingMethodDto {
  account: string = '';
  freight: FreightDto = null;
  method: ShippingDto = null;
}
