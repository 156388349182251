// @ts-ignore
import { Component, ContentChild, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { Execution } from '@appwrite/common';
import { Pagination, SortDirection } from '@mm-mono/common';
import { Observable, Subscription } from 'rxjs';
import { NgxTableService } from '../ngx-table.service';

@Component({
  selector: 'ngx-table',
  template: `
    <!-- begin panel -->
    <ng-container *ngIf="!hideSearch">
      <ngx-table-search></ngx-table-search>
    </ng-container>
    <ng-content *ngTemplateOutlet="content; context: { $implicit: dataRows }"></ng-content>
    @if (!content) {
    <table [class]="tableClass">
      <thead>
        <ng-content *ngTemplateOutlet="thead"></ng-content>
      </thead>
      <tbody [class]="tbodyClass">
        <ng-content *ngTemplateOutlet="tbody; context: { $implicit: dataRows }"></ng-content>
      </tbody>
      <tfoot>
        <!--										<tr>-->
        <!--											<td colspan="400">-->
        <!--												<pre>{{dataRows|json}}</pre>-->
        <!--											</td>-->
        <!--										</tr>-->
        <ng-content *ngTemplateOutlet="tfoot"></ng-content>
      </tfoot>
    </table>
    }
    <div class="flex flex-row gap-4">
      <div class="grow">
        @if (!hidePagination) {
        <ngx-table-pagination></ngx-table-pagination>
        }
      </div>
    </div>
  `,
})
export class NgxTableComponent<T = any> implements OnInit, OnDestroy {
  @Input() public defaultSortParams: Pagination['sortParams'] = {
    sortColumn: 'createdAt',
    sortDirection: SortDirection.DESC,
  };
  @Input() public stickyHeader: boolean = false;
  @Input() public hideSearch: boolean = false;
  @Input() public hidePagination: boolean = false;
  @Input() public hidePageSize: boolean = false;
  @Input() public tableClass: string = '';
  @Input() public tbodyClass: string = '';
  @Input() public requiresInput: boolean = false;

  @Input() public url: string;
  @Input() public fn: (page: number, pageSize: number, searchTerm: string, sortParams: any) => Observable<any>;

  @Output() loadingEmitter: EventEmitter<boolean> = new EventEmitter();

  @ContentChild('thead') thead: TemplateRef<any>;
  @ContentChild('tbody') tbody: TemplateRef<any>;
  @ContentChild('tfoot') tfoot: TemplateRef<any>;
  @ContentChild('content') content: TemplateRef<any>;

  public dataRows: Array<T>;
  public total: number;
  public pageSize: number;
  public searchTerm: string = '';
  private subscriptions: Subscription = new Subscription();

  constructor(private ngts: NgxTableService<T>) {}

  public ngOnInit(): void {
    console.log(this.content);
    console.log('ngOnInit');
    this.ngts.sortParams.next(this.defaultSortParams);
    this.subscriptions.add(this.ngts.loadingEmitter.subscribe((event) => this.loadingEmitter.emit(event)));
    this.subscriptions.add(this.ngts.dataSubject.subscribe((d) => (this.dataRows = d)));
    this.ngts.requiresInput.next(this.requiresInput);
    this.ngts.url.next(this.url);
    this.ngts.fn.next(this.fn);
  }

  public refresh() {
    this.ngts.refresh.emit();
  }

  public onSearch(event: string) {
    this.ngts.searchTerm.next(event);
  }

  public changePageSize(pageSize: number) {
    this.ngts.pageSize.next(pageSize);
  }

  public changePage(pageNumber: number) {
    this.ngts.page.next(pageNumber);
  }

  public ngOnDestroy(): void {
    console.log('ngOnDestroy');
    this.subscriptions.unsubscribe();
    this.ngts.onInit();
  }
}
