import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppwriteAccountService, AuthService } from '@appwrite/angular';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-verify',
  template: `
    <div class="fixed inset-0 bottom-0 left-0 right-0 top-0 z-[-1] block bg-[url('/assets/views/auth/cleveland-dust-collector.jpg')] bg-cover opacity-30"></div>
    <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <a [routerLink]="['/']" class="flex items-center">
          <minnich-logo [showSubtext]="true" [style.width]="200" class="mx-auto  w-auto"></minnich-logo>
        </a>
      </div>
      <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <h2 class="mb-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Account Verification</h2>
          <div class="space-y-6">
            <p class="leading-6">
              @if (!verificationSent) { Thank you for using Minnich Manufacturing online services! In order to proceed, we need to verify your email address.
              Press the button below and we will send you a verification email.
              <button
                (click)="sendVerificationEmail()"
                class="bg-accent-600 hover:bg-accent-500 focus-visible:outline-accent-600 mt-4 flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              >
                Send Verification Email
              </button>
              } @else { Your verification email has been sent to the email address you provided during registration. If you have not received it, check your
              junk folder. Otherwise contact Minnich Manufacturing. }
            </p>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./auth-state.styles.scss'],
})
export class AuthVerifyComponent implements OnInit {
  public verificationSent: boolean = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private messageService: MessageService,
    private authService: AuthService,
    private appwriteAccountService: AppwriteAccountService,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.route.queryParamMap
        .pipe(
          filter((params) => Boolean(params.get('userId')) && Boolean(params.get('secret'))),
          mergeMap((params) => this.appwriteAccountService.updateVerification(params.get('userId'), params.get('secret')))
        )
        .subscribe(
          (params) => {
            this.messageService.add({ severity: 'success', summary: 'Your account has been verified!', life: 8000 });
            this.router.navigate(['/']);
          },
          () => {
            this.messageService.add({ severity: 'success', summary: 'Your verification email has expired. Please try again.', life: 8000 });
            this.router.navigate(['/verify']);
          }
        )
    );
  }

  public sendVerificationEmail() {
    this.appwriteAccountService.createVerification('https://www.minnich-mfg.com/verify');
    this.verificationSent = true;
  }
}
