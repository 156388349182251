import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppwriteAccountService, AppwriteException, AuthService, mustMatch } from '@appwrite/angular';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  template: `
    <div class="fixed inset-0 bottom-0 left-0 right-0 top-0 z-[-1] block bg-[url('/assets/views/auth/cleveland-dust-collector.jpg')] bg-cover opacity-30"></div>
    <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <a [routerLink]="['/']" class="flex items-center">
          <minnich-logo [showSubtext]="true" [style.width]="200" class="mx-auto  w-auto"></minnich-logo>
        </a>
      </div>
      <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <h2 class="mb-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Reset Password</h2>
          <form (submit)="onReset()" [formGroup]="formData" class="space-y-6">
            <!--            <input-label formControlName="password" label="Password" type="password" autocomplete="new-password"></input-label>-->
            <!--            <input-label formControlName="passwordConfirm" label="Password Confirm" type="password" autocomplete="new-password"></input-label>-->

            <div class="form-group">
              <label class="mb-2 block leading-6 text-gray-900" for="password">Password</label>
              <p-password [feedback]="true" formControlName="password" id="password"></p-password>
            </div>
            <div class="form-group">
              <label class="mb-2 block leading-6 text-gray-900" for="passwordConfirm">Password Confirm</label>
              <input formControlName="passwordConfirm" id="passwordConfirm" pInputText type="password" />
            </div>
            <div>
              <button
                class="bg-accent-600 hover:bg-accent-500 focus-visible:outline-accent-600 flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                type="submit"
              >
                Change Password
              </button>
            </div>
            <p class="text-center">Already have an account? <a class="text-accent-600 hover:text-accent-500 font-semibold" routerLink="/login">Log In</a></p>
          </form>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./auth-state.styles.scss'],
})
export class AuthResetComponent implements OnInit, OnDestroy {
  public formData: UntypedFormGroup;
  private subscription: Subscription;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private appwriteAccountService: AppwriteAccountService
  ) {}

  ngOnInit() {
    this.subscription = this.route.queryParams.subscribe((params) => {
      console.log(params);
      this.formData = this.formBuilder.group(
        {
          userId: [params.userId, [Validators.required]],
          secret: [params.secret, [Validators.required]],
          password: ['', [Validators.required, Validators.minLength(8)]],
          passwordConfirm: ['', [Validators.required, Validators.minLength(8)]],
        },
        {
          validators: [mustMatch('password', 'passwordConfirm')],
        }
      );
    });
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public async onReset() {
    try {
      if (this.formData.valid) {
        const data = this.formData.value;
        await this.appwriteAccountService.updateRecovery(data.userId, data.secret, data.password);
        this.messageService.add({ severity: 'success', summary: 'Your password has been reset. Please log in with your new password.' });
        this.router.navigate(['/login']);
      }
    } catch (e) {
      if (e instanceof AppwriteException) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: e.message });
      } else if (e instanceof HttpErrorResponse) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: e.error.message });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An unknown error has occurred.' });
      }
      console.error(e);
    }
  }
}
