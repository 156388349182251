import { Injectable } from '@angular/core';
import { Payload } from '@appwrite/common';
import { AppwriteException } from '../exceptions';
import { prepareSearchParams } from '../util';
import { AppwriteService } from './appwrite.service';

@Injectable()
export class AppwriteAvatarsService {
  constructor(private appwriteService: AppwriteService) {}

  /**
   * Get browser icon
   *
   * You can use this endpoint to show different browser icons to your users.
   * The code argument receives the browser code as it appears in your user [GET
   * /account/sessions](https://appwrite.io/docs/references/cloud/client-web/account#getSessions)
   * endpoint. Use width, height and quality arguments to change the output
   * settings.
   *
   * When one dimension is specified and the other is 0, the image is scaled
   * with preserved aspect ratio. If both dimensions are 0, the API provides an
   * image at source quality. If dimensions are not specified, the default size
   * of image returned is 100x100px.
   *
   * @param {string} code
   * @param {number} width
   * @param {number} height
   * @param {number} quality
   * @throws {AppwriteException}
   * @returns URL
   */
  getBrowser(code: string, width?: number, height?: number, quality?: number): URL {
    const urlPath = '/avatars/browsers/{code}'.replace('{code}', code);
    const payload: Payload = { width, height, quality };

    if (typeof code === 'undefined') {
      throw new AppwriteException('Missing required parameter: "code"');
    }

    const uri = new URL(this.appwriteService.config.endpoint + urlPath);
    payload['project'] = this.appwriteService.config.project;
    prepareSearchParams(uri, payload);

    return uri;
  }

  /**
   * Get credit card icon
   *
   * The credit card endpoint will return you the icon of the credit card
   * provider you need. Use width, height and quality arguments to change the
   * output settings.
   *
   * When one dimension is specified and the other is 0, the image is scaled
   * with preserved aspect ratio. If both dimensions are 0, the API provides an
   * image at source quality. If dimensions are not specified, the default size
   * of image returned is 100x100px.
   *
   *
   * @param {string} code
   * @param {number} width
   * @param {number} height
   * @param {number} quality
   * @throws {AppwriteException}
   * @returns URL
   */
  getCreditCard(code: string, width?: number, height?: number, quality?: number): URL {
    const urlPath = '/avatars/credit-cards/{code}'.replace('{code}', code);
    const payload: Payload = { width, height, quality };

    if (typeof code === 'undefined') {
      throw new AppwriteException('Missing required parameter: "code"');
    }

    const uri = new URL(this.appwriteService.config.endpoint + urlPath);
    payload['project'] = this.appwriteService.config.project;
    prepareSearchParams(uri, payload);

    return uri;
  }

  /**
   * Get favicon
   *
   * Use this endpoint to fetch the favorite icon (AKA favicon) of any remote
   * website URL.
   *
   *
   * @param {string} url
   * @throws {AppwriteException}
   * @returns URL
   */
  getFavicon(url: string): URL {
    const urlPath = '/avatars/favicon';
    const payload: Payload = { url };

    if (typeof url === 'undefined') {
      throw new AppwriteException('Missing required parameter: "url"');
    }

    const uri = new URL(this.appwriteService.config.endpoint + urlPath);
    payload['project'] = this.appwriteService.config.project;
    prepareSearchParams(uri, payload);

    return uri;
  }

  /**
   * Get country flag
   *
   * You can use this endpoint to show different country flags icons to your
   * users. The code argument receives the 2 letter country code. Use width,
   * height and quality arguments to change the output settings. Country codes
   * follow the [ISO 3166-1](https://en.wikipedia.org/wiki/ISO_3166-1) standard.
   *
   * When one dimension is specified and the other is 0, the image is scaled
   * with preserved aspect ratio. If both dimensions are 0, the API provides an
   * image at source quality. If dimensions are not specified, the default size
   * of image returned is 100x100px.
   *
   *
   * @param {string} code
   * @param {number} width
   * @param {number} height
   * @param {number} quality
   * @throws {AppwriteException}
   * @returns URL
   */
  getFlag(code: string, width?: number, height?: number, quality?: number): URL {
    const urlPath = '/avatars/flags/{code}'.replace('{code}', code);
    const payload: Payload = { width, height, quality };

    if (typeof code === 'undefined') {
      throw new AppwriteException('Missing required parameter: "code"');
    }

    const uri = new URL(this.appwriteService.config.endpoint + urlPath);
    payload['project'] = this.appwriteService.config.project;
    prepareSearchParams(uri, payload);

    return uri;
  }

  /**
   * Get image from URL
   *
   * Use this endpoint to fetch a remote image URL and crop it to any image size
   * you want. This endpoint is very useful if you need to crop and display
   * remote images in your app or in case you want to make sure a 3rd party
   * image is properly served using a TLS protocol.
   *
   * When one dimension is specified and the other is 0, the image is scaled
   * with preserved aspect ratio. If both dimensions are 0, the API provides an
   * image at source quality. If dimensions are not specified, the default size
   * of image returned is 400x400px.
   *
   *
   * @param {string} url
   * @param {number} width
   * @param {number} height
   * @throws {AppwriteException}
   * @returns URL
   */
  getImage(url: string, width?: number, height?: number): URL {
    const urlPath = '/avatars/image';
    const payload: Payload = { url, width, height };

    if (typeof url === 'undefined') {
      throw new AppwriteException('Missing required parameter: "url"');
    }

    const uri = new URL(this.appwriteService.config.endpoint + urlPath);
    payload['project'] = this.appwriteService.config.project;
    prepareSearchParams(uri, payload);

    return uri;
  }

  /**
   * Get user initials
   *
   * Use this endpoint to show your user initials avatar icon on your website or
   * app. By default, this route will try to print your logged-in user name or
   * email initials. You can also overwrite the user name if you pass the 'name'
   * parameter. If no name is given and no user is logged, an empty avatar will
   * be returned.
   *
   * You can use the color and background params to change the avatar colors. By
   * default, a random theme will be selected. The random theme will persist for
   * the user's initials when reloading the same theme will always return for
   * the same initials.
   *
   * When one dimension is specified and the other is 0, the image is scaled
   * with preserved aspect ratio. If both dimensions are 0, the API provides an
   * image at source quality. If dimensions are not specified, the default size
   * of image returned is 100x100px.
   *
   *
   * @param {string} name
   * @param {number} width
   * @param {number} height
   * @param {string} background
   * @throws {AppwriteException}
   * @returns URL
   */
  getInitials(name?: string, width?: number, height?: number, background?: string): URL {
    const urlPath = '/avatars/initials';
    const payload: Payload = { name, width, height, background };

    const uri = new URL(this.appwriteService.config.endpoint + urlPath);
    payload['project'] = this.appwriteService.config.project;
    prepareSearchParams(uri, payload);

    return uri;
  }

  /**
   * Get QR code
   *
   * Converts a given plain text to a QR code image. You can use the query
   * parameters to change the size and style of the resulting image.
   *
   *
   * @param {string} text
   * @param {number} size
   * @param {number} margin
   * @param {boolean} download
   * @throws {AppwriteException}
   * @returns URL
   */
  getQR(text: string, size?: number, margin?: number, download?: boolean): URL {
    const urlPath = '/avatars/qr';
    const payload: Payload = { text, size, margin, download };

    if (typeof text === 'undefined') {
      throw new AppwriteException('Missing required parameter: "text"');
    }

    const uri = new URL(this.appwriteService.config.endpoint + urlPath);
    payload['project'] = this.appwriteService.config.project;
    prepareSearchParams(uri, payload);

    return uri;
  }
}
