import { Route } from '@angular/router';
import { AuthGuard } from '@appwrite/angular';
import { PortalIndexComponent } from '../../../../libs/modules/account/client/src/lib/portal/portal-index.component';
import { AppComponent } from './app.component';
import { errorAbstractRoute, errorsRoutes } from './errors/errors.states';
import { HomeComponent } from './views/public/home/home.component';
import { NewsService } from './views/public/news/news.service';
import { ProductsService } from './views/public/products/products.service';

const homeRoute: Route = {
  path: '',
  component: HomeComponent,
};

const dealersRoutes: Route = {
  path: 'dealers',
  loadComponent: () => import('./views/public/dealers/dealers.component').then((m) => m.DealersComponent),
};

const contactRoutes: Route = {
  path: 'contact',
  children: [
    { path: '', loadComponent: () => import('./views/public/contact/contact.component').then((m) => m.ContactComponent) },
    { path: 'thanks', loadComponent: () => import('./views/public/contact/contact-thanks.component').then((m) => m.ContactThanksComponent) },
  ],
};
const meetTheTeamRoutes: Route = {
  path: 'meet-the-team',
  loadComponent: () => import('./views/public/meet-the-team/meet-the-team.component').then((m) => m.MeetTheTeamComponent),
};
const aboutRoutes: Route = {
  path: 'about',
  loadComponent: () => import('./views/public/about/about.component').then((m) => m.AboutComponent),
};

const newsRoutes: Route = {
  path: 'news',
  providers: [NewsService],
  children: [
    {
      path: '',
      loadComponent: () => import('./views/public/news/articles.component').then((m) => m.ArticlesComponent),
    },
    {
      path: ':page',
      loadComponent: () => import('./views/public/news/articles.component').then((m) => m.ArticlesComponent),
    },
    {
      path: 'article/:article',
      loadComponent: () => import('./views/public/news/article.component').then((m) => m.ArticleComponent),
    },
  ],
};

const legalRoutes: Route = {
  path: 'legal',
  children: [
    { path: 'terms', loadComponent: () => import('./views/public/legal/legal.component').then((m) => m.TermsComponent) },
    { path: 'privacy', loadComponent: () => import('./views/public/legal/legal.component').then((m) => m.PrivacyComponent) },
    { path: 'monitor-app-terms', loadComponent: () => import('./views/public/legal/legal.component').then((m) => m.MonitorAppTermsComponent) },
    { path: 'monitor-app-eula', loadComponent: () => import('./views/public/legal/legal.component').then((m) => m.MonitorAppEulaComponent) },
  ],
};

const videosRoutes: Route = {
  path: 'videos',
  children: [
    { path: '', loadComponent: () => import('./views/public/videos/videos.component').then((m) => m.VideosComponent) },
    { path: ':url', loadComponent: () => import('./views/public/videos/video.component').then((m) => m.VideoComponent) },
  ],
};
const productsRoutes: Route = {
  path: 'products',
  providers: [ProductsService],
  children: [
    { path: '', loadComponent: () => import('./views/public/products/components/products-categories.component').then((m) => m.ProductsCategoriesComponent) },
    {
      path: ':paramCategory',
      loadComponent: () => import('./views/public/products/components/products-grade.component').then((m) => m.ProductsGradeComponent),
    },
    {
      path: ':paramCategory/:paramGrade',
      loadComponent: () => import('./views/public/products/components/products-products.component').then((m) => m.ProductsProductsComponent),
    },
    {
      path: ':paramCategory/:paramGrade/:paramProduct',
      loadComponent: () => import('./views/public/products/components/products-product.component').then((m) => m.ProductsProductComponent),
    },
  ],
};
const logConverterRoutes: Route = {
  path: 'avcc-logs',
  children: [
    {
      path: 'converter',
      loadComponent: () => import('./views/public/avcc-logs/converter.component').then((m) => m.ConverterComponent),
    },
  ],
};
const usedInventoryRoutes: Route = {
  path: 'used-inventory',
  loadComponent: () => import('./views/public/used-inventory/used-inventory.component').then((m) => m.UsedInventoryComponent),
};

const qrRoutes: Route = {
  path: 'qr',
  loadChildren: () => import('./views/public/landing-pages/qr-code/qr-landing-page.module').then((m) => m.QrLandingPageModule),
};
const formsRoutes: Route = {
  path: 'forms',
  loadChildren: () => import('./views/public/landing-pages/forms/forms.module').then((m) => m.FormsModule),
};
const privateRoutes: Route = {
  path: 'portal',
  canActivate: [AuthGuard],
  canActivateChild: [AuthGuard],
  component: PortalIndexComponent,

  children: [
    {
      path: 'account',
      loadChildren: () => import('@mm-mono/modules/account/client').then((m) => m.ModulesAccountClientModule),
    },
    {
      path: 'shop',
      loadChildren: () => import('@mm-mono/modules/store-front/client').then((m) => m.ModulesStoreFrontClientModule),
    },
  ],
};
export const appState: Route = {
  path: '',
  component: AppComponent,
  children: [
    homeRoute,
    dealersRoutes,
    contactRoutes,
    meetTheTeamRoutes,
    aboutRoutes,
    newsRoutes,
    legalRoutes,
    videosRoutes,
    productsRoutes,
    qrRoutes,
    privateRoutes,
    formsRoutes,
    logConverterRoutes,
    usedInventoryRoutes,
    errorsRoutes,
    errorAbstractRoute,
  ],
};
