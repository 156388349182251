import { Component, Input, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export interface AlertModalOptions {
  message: string;
  header?: string;
  acceptButtonLabel?: string;
}

@Component({
  selector: 'alert-modal-component',
  template: `
    <div class="mt-2">
      <p class="text-gray-500">{{ message }}</p>
    </div>
    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <button
        (click)="accept()"
        type="button"
        class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
      >
        {{ acceptButtonLabel }}
      </button>
    </div>
  `,
})
export class AlertDialog implements OnInit {
  @Input() message: string;
  @Input() title: string = 'Alert';
  @Input() acceptButtonLabel: string = 'Ok';

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  public ngOnInit(): void {
    console.log(`Opened ${this.constructor.name} with`, this.config);
    Object.keys(this.config?.data).forEach((k) => (this[k] = this.config?.data?.[k] ?? this[k]));
  }

  public accept() {
    this.ref.close(true);
  }
}
